<template>
  <div v-bind:class="[!getCurrentView || showLoader ? 'vh-100 d-flex flex-column justify-content-center' : null]">
    <Loader v-show="showLoader" />
    <div id="app" class="vh-100" v-show="!showLoader">
      <router-view />
    </div>
  </div>
</template>

<script>
import { ACTION_TYPES } from './store/action-types';
import { mapGetters } from 'vuex';
// temporary commented due to an improvement, import Header from './components/Header.vue';
import { STORES, VIEWS_ID } from './constants/NpsConstants';
import { SATISFACTION_SURVEY_URL } from './config/constants';
import router from './router/index';
import { MUTATION_TYPES } from './store/mutation-types';
import Loader from './components/Loader';

export default {
  name: 'App',
  components: {
    //temporary commented due to an improvement, Header,
    Loader,
  },
  computed: {
    ...mapGetters(STORES.POLL_SETTINGS, [
      'getCurrentView',
      'getCurrentViewId',
      'getTheme',
      'isPollFinished',
      'isSatisfactionSurvey',
      'getIsLoadData',
    ]),

    showLoader() {
      return this.getCurrentViewId === VIEWS_ID.INITIAL_VIEW_ID || this.getIsLoadData;
    },
  },

  methods: {
    ///Redirects to the main domain of the brand
    redirectToMainDomain() {
      window.location = 'https://www.robinfood.com';
    },
  },

  mounted() {
    let pollSlug = this.$router.history.current.path;

    //Delete slash
    pollSlug = pollSlug.slice(1);

    //If it doesn't come it redirects to another web page
    if (!pollSlug) {
      this.redirectToMainDomain();
    } else {
      this.$store.dispatch(`${STORES.POLL_SETTINGS}/${ACTION_TYPES.GET_POLL_SETTINGS}`, pollSlug);
      this.$store.commit(`${STORES.SEND_POLL_INFO_STORE}/${MUTATION_TYPES.SET_POLL_INFO}`, this.$route.query);
    }

    const url = new URL(`${SATISFACTION_SURVEY_URL}/${pollSlug}`);
    Object.keys(this.$route.query).forEach(key => {
      url.searchParams.set(key, this.$route.query[key]);
    });
    this.$store.commit(`${STORES.POLL_SETTINGS}/${MUTATION_TYPES.SET_SATISFACTION_SURVEY_URL}`, url.toString());
  },

  updated() {
    this.$i18n.locale = sessionStorage.getItem('locale');
  },

  watch: {
    getCurrentViewId(newCurrentViewId, oldCurrentViewId) {
      if (oldCurrentViewId === VIEWS_ID.INITIAL_VIEW_ID && newCurrentViewId > 0) {
        router.replace({ name: 'Home' });
      }
      if (newCurrentViewId === VIEWS_ID.FINISH_POLL_VIEW_ID) {
        router.replace({ name: 'data' });
      }
      if (newCurrentViewId === VIEWS_ID.UNIQUE_POLL_VIEW_ID) {
        router.replace({ name: 'resolved' });
      }
      if (newCurrentViewId === VIEWS_ID.REDIRECT_VIEW_ID) {
        this.redirectToMainDomain();
      }
    },

    isPollFinished(newIsFinished) {
      if (newIsFinished) {
        router.replace({ name: 'finished' });
      }
    },
  },
};
</script>

<style lang="scss">
#app {
  font-family: Montserrat, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}
@media (min-width: 768px) {
  #nav {
    height: 10%;
  }
}
</style>
