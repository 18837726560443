import axios from 'axios';
import { httpErrorStatus } from '@/config/constants';
import AnalyticsManager from '../analytics/analytics';
const { VUE_APP_API_BASE_URL } = process.env;

export default class BaseHttpClient {
  constructor() {
    this.axiosHttpClient = axios.create({
      baseURL: VUE_APP_API_BASE_URL,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  init() {
    this.response = {};
    this.success = null;
    this.statusCode = null;
  }

  onErrorListener() {}
  onSuccessListener() {}
  onDataListener() {}

  get httpClient() {
    this.init();
    return this.axiosHttpClient;
  }

  setError(error) {
    this.success = false;
    if (error.response) {
      this.setResponse(error.response);
    } else {
      this.onErrorListener(error.message);
    }
  }

  setResponse(httpResponse, resultMessage) {
    if (httpResponse.data) {
      this.action = httpResponse.data.action;
      this.code = httpResponse.data.code;
      this.response = httpResponse.data;
      this.statusCode = httpResponse.status;
      this.message = resultMessage || this.response.message || httpResponse.statusText;
      this.success = this.statusCode === 200;
      if (httpErrorStatus.includes(this.statusCode)) {
        this.onErrorListener(this.message, this.statusCode);
      }

      if (this.success) {
        if (resultMessage) this.onSuccessListener(this.message);
        this.onDataListener(this.response.data, this.code, this.action);
      } else {
        this.onErrorListener(this.message, this.statusCode);
        AnalyticsManager.sendServiceError(
          VUE_APP_API_BASE_URL + httpResponse.config.url,
          this.message,
          this.statusCode,
        );
      }
    }
  }

  onError(listener) {
    this.onErrorListener = listener;
  }

  onSuccess(listener) {
    this.onSuccessListener = listener;
  }

  onData(listener) {
    this.onDataListener = listener;
  }
}
