<template>
  <div class="row mt-4">
    <div class="col-12 col-lg-8 offset-lg-2">
      <div class="px-4 d-flex justify-content-between">
        <button
          v-for="(option, index) in answersOptions"
          v-bind:key="index"
          @click="selectOption($event, option, questionId)"
          :style="styleObject"
          type="button"
          class="btn btn-answer-option w-50 btn-outline-primary mr-2 py-3 rounded-xl"
        >
          {{ option.value }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: 'QuestionType5',
  props: {
    mainColor: String,
    secondaryColor: String,
    accentColor: String,
    answersOptions: Array,
    selectOption: Function,
    questionId: Number,
  },
  computed: {
    styleObject: function() {
      return {
        '--mainColor': this.mainColor,
        '--secondaryColor': this.secondaryColor,
        '--color-hover': this.accentColor,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-answer-option {
  color: var(--mainColor);
  border-color: var(--color-hover);
  border-width: 2px;
  font-weight: bolder;
  &:hover {
    background-color: var(--color-hover);
    color: var(--secondaryColor);
    cursor: pointer;
  }
  &:focus {
    background-color: var(--color-hover);
    color: var(--secondaryColor);
    border-color: transparent;
    cursor: pointer;
  }
}
.btn-answer-option:last-child {
  background-color: var(--color-hover);
  color: var(--secondaryColor);
  margin: 0 !important;
  &:hover {
    background: none;
    color: var(--mainColor);
  }
}
</style>
