<template>
  <div v-if="showButton" class="row position-absolute">
    <div class="col-12 col-xl-10 offset-xl-1">
      <div class="px-xl-5 text-left">
        <button type="button" class="btn btn-link" @click="goBackToLastQuestion">
          <svg
            :style="{ color }"
            width="2em"
            height="2em"
            viewBox="0 0 16 16"
            class="bi bi-chevron-left"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
            />
          </svg>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { STORES } from '../constants/NpsConstants';

export default {
  name: 'ButtonBack',
  props: {
    color: String,
    goBackToLastQuestion: Function,
  },
  computed: {
    ...mapGetters(STORES.POLL_SETTINGS, ['isFirstView', 'isSatisfactionSurvey']),

    showButton() {
      return this.isSatisfactionSurvey || !this.isFirstView;
    },
  },
};
</script>
