<template>
  <div class="row mb-3 mb-md-0 mt-4" :class="{ 'poll-question-3__satisfaction': isSatisfactionSurvey }">
    <div class="col-12 col-xl-10 offset-xl-1">
      <div class="text-left px-4 px-md-0 px-xl-4">
        <div class="d-md-flex flex-wrap px-4 px-md-0 py-1 optionsWrapper justify-content-between">
          <div class="form-check py-2 border-bottom" v-for="(option, index) in answersOptions" v-bind:key="index">
            <input
              @click="otherOption($event, option, questionId)"
              class="form-check-input"
              type="radio"
              name="answer"
              :id="'answer' + index"
              :value="option.value"
              :class="optionIsActive(option.value) ? 'active' : ''"
              :checked="optionIsActive(option.value)"
            />
            <label class="form-check-label" :for="'answer' + index" style="font-size: 0.8rem">
              {{ answersText[index] }}
            </label>
          </div>
        </div>
        <div v-show="showTextarea" class="text-left mt-3">
          <span v-bind:style="{ color: mainColor, opacity: '0.57' }">Cuéntanos más</span>
          <TextAreaComponent
            :answer="answerTextarea"
            class="mt-2"
            :question="question"
            :handleTextArea="handleTextArea"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TextAreaComponent from '@/components/TextAreaComponent';
import { ANSWER_TYPES } from '../../../constants/NpsConstants';
import { InitialValuesMixin, OptionMixin } from '../../../mixins';

export default {
  name: 'QuestionType3',

  mixins: [InitialValuesMixin, OptionMixin],

  components: {
    TextAreaComponent,
  },

  data() {
    return {
      answersText: [],
      cleanTextarea: false,
      questionSelected: null,
      showTextarea: false,
    };
  },

  computed: {
    answerTextarea() {
      return this.answersOptions.find(item => item.type === ANSWER_TYPES.ANSWER_TYPE_ADDITIONAL_TEXT_FIELD);
    },
  },

  props: {
    accentColor: String,
    answersOptions: Array,
    handleTextArea: Function,
    mainColor: String,
    question: Object,
    questionId: Number,
    secondaryColor: String,
    selectOption: Function,
    isSatisfactionSurvey: Boolean,
  },

  methods: {
    otherOption(event, option, question) {
      this.showTextarea = option.type === ANSWER_TYPES.ANSWER_TYPE_ADDITIONAL_TEXT_FIELD;
      this.selectOption(event, option, question);
      this.cleanTextarea = this.showTextarea;
    },
  },

  mounted() {
    this.answersText = this.answersOptions.map(item => item.value);
  },
};
</script>
