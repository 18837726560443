<template>
  <div class="row">
    <div
      class="col-12 col-lg-10 offset-lg-1 col-xl-8 offset-xl-2 text-md-center"
      :class="{ 'text-left': !isSatisfactionSurvey, 'poll-question-header__satisfaction': isSatisfactionSurvey }"
    >
      <div class="px-4">
        <h1 class="mb-3" v-bind:style="{ color: mainColor, fontWeight: '600' }">{{ headTitle }}</h1>
        <span translate="no" class="poll-question-header-description" v-bind:style="{ color: mainColor }">{{
          question
        }}</span>
      </div>
    </div>
    <div class="col-12 col-xl-10 offset-xl-1 text-left d-flex justify-content-md-center" v-if="!isSatisfactionSurvey">
      <Steper v-if="showSteper" :color="accentColor" />
    </div>
  </div>
</template>

<script>
import Steper from './Steper';
// @ is an alias to /src
export default {
  name: 'QuestionHeader',
  props: {
    headTitle: String,
    question: String,
    mainColor: String,
    margin: String,
    accentColor: String,
    questionId: Number,
    showSteper: Boolean,
    isSatisfactionSurvey: Boolean,
  },
  components: {
    Steper,
  },
};
</script>

<style lang="scss">
.poll-question-header-description {
  opacity: 0.57;
}
.poll-question-header__satisfaction {
  margin-bottom: 1rem;
  h1 {
    font-size: 1.25rem;
  }
  .poll-question-header-description {
    font-size: 1rem;
    font-weight: 300;
    opacity: 0.8;
  }
}
</style>
