<template>
  <div class="wrapper" v-bind:style="getBackgroundStyles">
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-6 offset-md-3">
          <div
            class="mainWrapper d-flex flex-column justify-content-between"
            :class="{ mainWrapper__satisfaction: isSatisfactionSurvey }"
            v-bind:style="{ fontFamily: [this.getTheme.secondaryFont, this.getTheme.mainFont] }"
          >
            <div class="mb-md-2">
              <div class="row mb-5">
                <div class="col-12 mt-5">
                  <img :src="this.getLogo" alt="logo" class="img-fluid" width="100" height="100" />
                </div>
              </div>
              <!-- Dynamic Question -->
              <div class="row mb-5">
                <div
                  class="
                    mainWrapper-description
                    col-12 col-lg-10
                    offset-lg-1
                    col-xl-8
                    offset-xl-2
                    text-left text-md-center
                  "
                  :class="{ 'mainWrapper-description__satisfaction': isSatisfactionSurvey }"
                >
                  <div class="px-4">
                    <h1 class="mb-3" v-bind:style="{ color: this.getTheme.mainTextColor, fontWeight: '600' }">
                      {{ getTitle }}
                    </h1>
                    <span v-bind:style="{ color: this.getTheme.mainTextColor, opacity: '0.75' }">
                      {{ getDescription }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapGetters } from 'vuex';
import { STORES } from '../constants/NpsConstants';
// @ is an alias to /src

export default {
  name: 'FinishedSurvey',

  computed: {
    ...mapGetters(STORES.POLL_SETTINGS, [
      'getLogo',
      'getTheme',
      'showBottomButton',
      'getBackgroundImage',
      'isSatisfactionSurvey',
    ]),

    ...mapGetters(STORES.SEND_POLL_INFO_STORE, ['getMessageFinished']),

    getBackgroundStyles() {
      return this.isSatisfactionSurvey
        ? {
            background: `url(${this.getBackgroundImage}) center center no-repeat`,
            backgroundColor: this.getTheme.badgeBackgroundColor,
            backgroundSize: 'auto 100%',
            backgroundAttachment: 'fixed',
          }
        : { backgroundColor: this.getTheme.backgroundColor };
    },

    getTitle() {
      return this.$t(this.getMessageFinished?.title || 'finishedSurvey.title');
    },

    getDescription() {
      return this.$t(this.getMessageFinished?.description || 'finishedSurvey.message');
    },
  },
};
</script>

<style lang="scss" scoped>
.mainWrapper__satisfaction {
  justify-content: center !important;
  .mainWrapper-description__satisfaction {
    text-align: center !important;
    h1 {
      font-size: 1.25rem;
    }
    span {
      font-size: 1rem;
    }
  }
}
</style>
