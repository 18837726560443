export default class Question {
  constructor(answers, helpText, id, name, optionsSelected, type, value, helpTextOne, helpTextTwo) {
    this.answers = answers;
    this.helpText = helpText;
    this.id = id;
    this.name = name;
    this.optionsSelected = optionsSelected;
    this.type = type;
    this.value = value;
    this.initialHelpText = helpTextOne;
    this.finalHelpText = helpTextTwo;
  }
}
