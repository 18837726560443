<template>
  <div class="question-type-stars row mb-md-5">
    <div class="col-12 col-xl-10 offset-xl-1">
      <div class="px-xl-4">
        <div class="btn-toolbar mb-2" role="toolbar">
          <div class="btn-group w-100" role="group">
            <div class="starrater-container">
              <Star
                v-for="(option, index) in answersOptions"
                :active="optionShowActive(option)"
                :key="index"
                :color="color"
                @select="onSelectOption($event, option, question)"
                @hover="optionHover = option"
                @blur="optionHover = null"
              />
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-between" :style="{ color: textColor }">
          <strong :style="{ fontSize: '.75rem' }">{{ this.question.initialHelpText }}</strong>
          <strong :style="{ fontSize: '.75rem' }">{{ this.question.finalHelpText }}</strong>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Star from '@/components/Star.vue';
import { InitialValuesMixin, OptionMixin } from '../../../mixins';

// @ is an alias to /src
export default {
  name: 'QuestionType6',
  components: {
    Star,
  },
  data: () => ({
    optionActive: null,
    optionHover: null,
  }),

  mixins: [InitialValuesMixin, OptionMixin],

  props: {
    color: String,
    textColor: String,
    answersOptions: Array,
    currentView: Object,
    question: Object,
    selectOption: Function,
  },

  computed: {
    optionShowActive() {
      return option => {
        const optionSelected = this.optionHover || this.optionActive;
        if (optionSelected) {
          const selectedIndex = this.answersOptions.findIndex(item => item.id === optionSelected.id);
          const optionIndex = this.answersOptions.findIndex(item => item.id === option.id);
          return optionIndex <= selectedIndex;
        }
        return false;
      };
    },
  },

  methods: {
    onSelectOption($event, option, question) {
      this.optionActive = option;
      this.selectOption($event, option, question.id);
    },
  },

  mounted() {
    this.currentView && this.$analytics.sendViewOpened(this.currentView.viewName);

    if (this.questionSelected) {
      const { answers } = this.questionSelected;
      this.optionActive = answers[0];
    }
  },
};
</script>

<style lang="scss" scoped>
.starrater-container {
  display: flex;
  justify-content: space-around;
  width: 100%;
  .star-svg-container {
    width: 100%;
  }
}
</style>
