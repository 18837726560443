<template>
  <div class="my-4 px-4 px-md-0 px-xl-4">
    <div class="steper">
      <div class="steperIndicator" :style="indicatorStyles"></div>
    </div>
  </div>
</template>

<script>
import { STORES } from '../constants/NpsConstants';
export default {
  name: 'Steper',
  props: {
    color: String,
    defaultStep: Number,
  },
  computed: {
    // This method calculate indicator styles. Get the nps step and return its value
    // multiplied by 20 (the nps length suposed to be 5), to move the progress bar 20% on each step
    indicatorStyles() {
      const leftValue = this.step * 20;
      return { background: this.color, left: `${leftValue}%` };
    },

    // Get the nps step from store.
    step() {
      return this.defaultStep || this.$store.getters[`${STORES.SEND_POLL_INFO_STORE}/getPollStep`];
    },
  },
};
</script>

<style lang="scss" scoped>
.steper {
  position: relative;
  height: 9px;
  width: 100px;
  background-color: #e4e8ef;
  border-radius: 0.3rem;
  .steperIndicator {
    position: absolute;
    width: 25%;
    height: 100%;
    border-radius: inherit;
  }
}
</style>
