import Vue from 'vue';
import Vuex, { createLogger } from 'vuex';
import modules from './modules'; // import the auto exporter

Vue.use(Vuex);

// Global variables
const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
  //All modules defined under the route ./modules./*.store.js
  modules,
  // strict module activated on debug to ensure that state is only accessed
  // from mutations and actions. This reduces
  strict: debug,
  // set logger only for development since it launches a synchronous proccess
  // that would affect the performance on production.
  plugins: debug ? [createLogger()] : [],
});
