<template>
  <div class="wrapper" :style="getBackgroundStyles">
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-6 offset-md-3">
          <div
            class="mainWrapper d-flex flex-column justify-content-between"
            v-bind:style="{ fontFamily: [getTheme.secondaryFont, getTheme.mainFont] }"
          >
            <div class="poll-container" :class="{ 'poll-container__satisfaction': isSatisfactionSurvey }">
              <div class="mb-md-2">
                <div class="poll-header-brand my-5">
                  <ButtonBack
                    :color="getTheme.accentColor || getTheme.backgroundColor"
                    :goBackToLastQuestion="onGoToBack"
                  />
                  <img :src="getLogo" alt="logo" class="poll-brand-logo img-fluid mx-auto" width="100" height="100" />
                </div>
                <!-- Dynamic Question -->
                <template v-if="loadQuestionView">
                  <Survey
                    v-for="(question, index) in getFilteredQuestion"
                    :key="index"
                    :showSteper="index === 0"
                    :question="question"
                    :mainColor="getTheme.mainTextColor"
                    :secondaryColor="getTheme.secondaryColor"
                    :secondaryTextColor="getTheme.secondaryTextColor"
                    :accentColor="getTheme.accentColor"
                    :badgeBackgroundColor="getTheme.badgeBackgroundColor"
                    :selectOptionPill="selectOptionPill"
                    :selectOption="selectOption"
                    :handleInputs="handleInputs"
                    :handleTextArea="handleTextArea"
                    :backgroundColor="getTheme.backgroundColor"
                    :currentView="getCurrentView"
                    :isSatisfactionSurvey="isSatisfactionSurvey"
                  />
                </template>
              </div>
              <!-- Backimage -->
              <BackImage :image="getTheme.backgroundImage" v-show="!this.showBottomButton" />
            </div>

            <!-- Bottom section -->
            <div
              class="position-sticky"
              v-bind:style="{ background: !isSatisfactionSurvey ? getTheme.backgroundColor : null, bottom: 0 }"
            >
              <ErrorMessage :validationError="getValidationError" />

              <!-- Dynamic Button -->
              <ButtonNext
                v-if="this.showBottomButton"
                :backgroundColor="getButtonColor"
                :color="this.getTheme.secondaryTextColor"
                :nextQuestion="nextQuestion"
                :currentView="getNextViewId"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapMutations, mapGetters, mapState } from 'vuex';
import { ACTION_TYPES } from '../store/action-types';
import { MUTATION_TYPES } from '../store/mutation-types';
import { STORES, VIEWS_ID } from '../constants/NpsConstants';
import BackImage from '@/components/BackImage';
import ButtonNext from '@/components/ButtonNext.vue';
import ButtonBack from '@/components/ButtonBack.vue';
import ErrorMessage from '@/components/ErrorMessage.vue';
import Survey from './Survey/Survey.vue';
import { GoBackToLastQuestionMixin } from '../mixins';

export default {
  name: 'Home',

  mixins: [GoBackToLastQuestionMixin],

  data() {
    return {
      loadQuestionView: false,
    };
  },

  components: {
    ButtonNext,
    ButtonBack,
    BackImage,
    ErrorMessage,
    Survey,
  },

  watch: {
    getCurrentView: {
      handler() {
        this.loadQuestionView = false;
        setTimeout(() => {
          this.loadQuestionView = true;
        }, 1);
      },
      immediate: true,
    },
  },

  computed: {
    ...mapGetters(STORES.POLL_SETTINGS, [
      'getCurrentView',
      'getCurrentViewId',
      'getLogo',
      'getTheme',
      'showBottomButton',
      'isSatisfactionSurvey',
      'getBackgroundImage',
      'isFirstView',
      'getSatisfactonSurveyUrl',
    ]),

    ...mapGetters(STORES.SEND_POLL_INFO_STORE, ['getValidationError', 'getQuestionViewAnswered']),
    ...mapState(STORES.SEND_POLL_INFO_STORE, ['pollStep']),

    //Get next view id
    getNextViewId() {
      let nextViewId = VIEWS_ID.INITIAL_VIEW_ID;
      if (this.getCurrentView && this.getCurrentView.nextViewId) {
        nextViewId = this.getCurrentView.nextViewId;
      }
      return nextViewId;
    },

    //Get the questions of the current view
    getQuestions() {
      return this.getCurrentView ? this.getCurrentView.questions : [];
    },

    getFilteredQuestion() {
      return this.isSatisfactionSurvey ? this.getQuestionSurveySatisfaction : this.getQuestions;
    },

    getQuestionSurveySatisfaction() {
      const questionsAnswered = this.getQuestionViewAnswered;
      return this.getQuestions.filter((question, index) => {
        if (index === 0) return true;
        return !!questionsAnswered.find(questionAnswered => {
          return !!questionAnswered.answers.find(answer => answer.nextQuestionId === question.id);
        });
      });
    },

    getBackgroundStyles() {
      return this.isSatisfactionSurvey
        ? {
            background: `url(${this.getBackgroundImage}) center center no-repeat`,
            backgroundColor: this.getTheme.badgeBackgroundColor,
            backgroundSize: 'auto 100%',
            backgroundAttachment: 'fixed',
          }
        : { backgroundColor: this.getTheme.backgroundColor };
    },

    getButtonColor() {
      return this.isSatisfactionSurvey ? this.getTheme.backgroundColor : this.getTheme.accentColor;
    },
  },

  methods: {
    ...mapActions(
      [`${STORES.POLL_SETTINGS}/${ACTION_TYPES.GET_POLL_SETTINGS}`],
      [`${STORES.POLL_SETTINGS}/${ACTION_TYPES.ANSWER_QUESTIONS_FROM_VIEW}`],
    ),

    ...mapMutations([`${STORES.SEND_POLL_INFO_STORE}/${MUTATION_TYPES.ADD_ANSWERED_QUESTION}`]),

    selectOptionPill(event, answer, question) {
      this.toggleActivePill(event);
      if (question) {
        this.$store.commit(`${STORES.SEND_POLL_INFO_STORE}/${MUTATION_TYPES.ADD_ANSWERED_QUESTION}`, {
          answer: answer,
          questionId: question.id,
        });
      }
    },

    selectOption(event, answer, questionId) {
      this.toggleActive(event);
      this.$store.commit(`${STORES.SEND_POLL_INFO_STORE}/${MUTATION_TYPES.ADD_ANSWERED_QUESTION}`, {
        answer: answer,
        questionId: questionId,
      });
      // If view is yes/no, trigger the next question
      if (!this.showBottomButton) {
        this.nextQuestion();
      }
    },

    handleInputs(event, questionId) {
      this.answers[0] = questionId;
      event.target.name === 'phone'
        ? (this.answers[1] = { [event.target.name]: event.target.value })
        : (this.answers[2] = { [event.target.name]: event.target.value });
    },

    handleTextArea(answer, questionId, answerValue) {
      this.$store.commit(`${STORES.SEND_POLL_INFO_STORE}/${MUTATION_TYPES.ADD_ANSWERED_QUESTION}`, {
        answer: answer,
        answerValue: answerValue,
        questionId: questionId,
        replace: true,
      });
    },

    toggleActive(event) {
      const buttons = document.querySelectorAll('.btn-answer-option');
      buttons.forEach(button => {
        button.classList.remove('active');
      });
      event.target.classList.add('active');
    },

    toggleActivePill(event) {
      event.target.classList.contains('active')
        ? event.target.classList.remove('active')
        : event.target.classList.add('active');
    },

    nextQuestion(event) {
      this.$store.dispatch(`${STORES.SEND_POLL_INFO_STORE}/${ACTION_TYPES.ANSWER_QUESTIONS_FROM_VIEW}`);
    },

    onGoToBack() {
      if (this.isSatisfactionSurvey && this.isFirstView) {
        window.location = this.getSatisfactonSurveyUrl;
      } else {
        this.goBackToLastQuestion();
      }
    },
  },
};
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap');
h1 {
  font-size: 1.6rem;
}
span {
  font-size: 0.9rem;
}
strong {
  font-size: 0.8rem;
}
textarea {
  resize: none;
  &::placeholder {
    font-size: 0.8rem;
  }
}
input[type='text']::placeholder,
input[type='phone']::placeholder {
  font-size: 0.8rem;
}
.poll-brand-logo {
  max-width: 100px;
  max-height: 100px;
  object-fit: contain;
}
.optionsWrapper div.border-bottom:last-child {
  border: none !important;
}
.badge {
  background: white;
  border-color: white;
}
.rounded-xl {
  border-radius: 0.4rem;
}
.backImage {
  max-width: 300px;
  max-height: 250px;
}
.mainWrapper {
  position: relative;
}
.poll-container {
  &.poll-container__satisfaction {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .poll-header-brand {
      .position-absolute {
        top: 2rem;
      }
    }
  }
}
@media (max-width: 768px) {
  .mainWrapper {
    min-height: 100vh;
  }
  .optionsWrapper {
    background: white;
    border-radius: 0.4rem;
  }
}
@media (min-width: 768px) {
  .wrapper {
    min-height: 100%; //Should be 90%
  }
  .optionsWrapper div.border-bottom {
    border: none !important;
    background: white;
    border-radius: 0.4rem;
    padding-left: 2rem !important;
    margin-bottom: 0.5rem;
    width: 49%;
  }
}
</style>
