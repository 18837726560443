import {
  COLORS,
  DEFAULT_EMPTY_STRING,
  DEFAULT_FONT,
  STORES,
  VIEWS_ID,
  VIEW_TYPES,
} from '../../constants/NpsConstants.js';
import { MUTATION_TYPES } from '../mutation-types';
import { FIELDS } from '../../constants/ApiConstants';
import { ACTION_TYPES } from '../action-types.js';
import themeRequest from '../../http/requests/ThemeRequest.js';
import pollRequest from '../../http/requests/PollRequest.js';
import shippingsRequest from '../../http/requests/ShippingsRequest';
import Answer from '../../models/Answer';
import Question from '../../models/Question';
import View from '../../models/View';
import { POLL_TYPES } from '../../constants/PollConstants.js';

// Getters
export const getters = {
  ///Get the hexadecimal code that repressents the accent color of the screen
  getAccentColor(state) {
    return state.theme.accentColor !== undefined ? state.theme.accentColor : COLORS.WHITE;
  },

  ///Get the hexadecimal code that repressents the bacground color of the screen
  getBackgroundColor(state) {
    return state.theme.backgroundColor !== undefined ? state.theme.backgroundColor : COLORS.WHITE;
  },

  ///Get the url to the resource that will be displayed as background
  getBackgroundImage(state) {
    return state.theme.backgroundImage !== undefined ? state.theme.backgroundImage : DEFAULT_EMPTY_STRING;
  },

  ///Gets the current id of the view that must be displayed
  getCurrentView(state) {
    if (state.views !== undefined && Array.isArray(state.views) && state.views.length > 0 && !state.finished) {
      return state.views.find(element => element.id === state.idCurrentView);
    }
    return undefined;
  },

  ///Gets the current id of the view that must be displayed
  getCurrentViewId(state) {
    return state.idCurrentView;
  },

  ///Gets isClickToBack property, indicating click action to the back button
  getIsClickToBack(state) {
    return state.isClickToBack;
  },

  ///Get the url to the resource that represents the logo of the brand
  getLogo(state) {
    return state.theme.logo !== undefined ? state.theme.logo : DEFAULT_EMPTY_STRING;
  },

  ///Get the string representing the main font to be used on titles and call to actions
  getMainFont(state) {
    return state.theme.mainFont !== undefined ? state.theme.mainFont : DEFAULT_FONT;
  },

  ///Get the color of all the primary texts on the screen
  getMainTextColor(state) {
    return state.theme.mainTextColor !== undefined ? state.theme.mainTextColor : COLORS.BLACK;
  },

  ///Get the color of all the texts that are shown over the accent color on the screen
  getTextColorOverAccent(state) {
    return state.theme.textColorOverAccent !== undefined ? state.theme.textColorOverAccent : COLORS.BLACK;
  },

  ///Get the font for the secondary text (paragraphs and auxiliary) on the screen
  getSecondaryFont(state) {
    return state.theme.secondaryFont !== undefined ? state.theme.secondaryFont : DEFAULT_FONT;
  },

  ///Get the color of the secondary text (paragraphs and auxiliary) on the screen
  getSecondaryTextColor(state) {
    return state.theme.secondaryTextColor !== undefined ? state.theme.secondaryTextColor : COLORS.BLACK;
  },

  //Gets the theme of the poll
  getTheme(state) {
    sessionStorage.setItem('locale', state.theme.locale);
    return state.theme;
  },

  //Get views
  getViews(state) {
    return state.views;
  },

  ///Method that returns true if is first view
  isFirstView(state) {
    return state.previewIndexIdCurrentView < 0;
  },

  ///Method that returns true if the poll has been completed
  isPollFinished(state) {
    return state.finished;
  },

  ///Method that returns the type of the view
  showBottomButton(state) {
    const view = state.views.find(element => element.id === state.idCurrentView);
    return (
      (view !== undefined ? view.typeId !== VIEW_TYPES.ANSWERS_DECIDES_NEXT_NO_BOTTOM_BUTTON : true) && !state.finished
    );
  },

  //Gets poll data
  getPollData(state) {
    return state.poll;
  },

  //Gets poll type
  getPollTypeData(state) {
    return state.poll ? state.poll.poll_type_id : null;
  },

  //Gets poll type
  isSatisfactionSurvey(state) {
    return state.poll ? state.poll.poll_type_id === POLL_TYPES.SATISFACTION : false;
  },

  //Gets poll data
  getIsLoadData(state) {
    return state.isLoadData;
  },

  //Gets poll data
  getSatisfactonSurveyUrl(state) {
    return state.satisfactionSurveyUrl;
  },
};

// Actions
const actions = {
  //Load all configuration needed to load the poll
  [ACTION_TYPES.GET_POLL_SETTINGS]({ commit, getters, dispatch, rootState }, pollSlug) {
    pollRequest.onData(response => {
      commit(`${STORES.SEND_POLL_INFO_STORE}/${MUTATION_TYPES.POLL_INITIALIZED}`, response.polls.name, { root: true });
      commit(MUTATION_TYPES.SET_POLL_VIEWS, response);
      if (getters.isSatisfactionSurvey && rootState.SendPollInfoStore?.shippingsUi) {
        const shippingUid = rootState.SendPollInfoStore?.shippingsUid;
        dispatch(ACTION_TYPES.GET_POLL_SHIPPING, shippingUid);
      }
    });

    themeRequest.onError(() => {
      commit(MUTATION_TYPES.SET_CURRENT_VIEW_ID, VIEWS_ID.REDIRECT_VIEW_ID);
    });

    themeRequest.onData(response => {
      const theme = response.setting;
      if (theme) {
        commit(MUTATION_TYPES.SET_POLL_THEME, theme);
        commit(MUTATION_TYPES.SET_POLL_DATA, response.poll);
        commit(`${STORES.SEND_POLL_INFO_STORE}/${MUTATION_TYPES.SET_POLL_ID}`, response.poll.id, { root: true });
        pollRequest.getPoll(response.poll.id);
      } else {
        commit(MUTATION_TYPES.SET_CURRENT_VIEW_ID, VIEWS_ID.REDIRECT_VIEW_ID);
      }
    });
    themeRequest.getTheme(pollSlug);
  },

  [ACTION_TYPES.GET_POLL_SHIPPING]({ commit, dispatch }, uid) {
    commit(MUTATION_TYPES.SET_IS_LOAD_DATA, true);
    shippingsRequest.onData(response => {
      if (validateDiffDatesInHours(new Date(response.createdAt), new Date())) {
        commit(
          `${STORES.SEND_POLL_INFO_STORE}/${MUTATION_TYPES.SET_SEND_INFO_MESSAGE_FINISHED}`,
          {
            title: 'finishedSurvey.surveyIsExpired.title',
            description: 'finishedSurvey.surveyIsExpired.description',
          },
          { root: true },
        );
        dispatch(`${STORES.SEND_POLL_INFO_STORE}/${ACTION_TYPES.SEND_POLL}`, { finish: true }, { root: true });
      } else if (response.answered) {
        commit(
          `${STORES.SEND_POLL_INFO_STORE}/${MUTATION_TYPES.SET_SEND_INFO_MESSAGE_FINISHED}`,
          {
            title: 'finishedSurvey.surveyIsAnswered.title',
            description: 'finishedSurvey.surveyIsAnswered.description',
          },
          { root: true },
        );
        dispatch(`${STORES.SEND_POLL_INFO_STORE}/${ACTION_TYPES.SEND_POLL}`, { finish: true }, { root: true });
      }
      setTimeout(() => {
        commit(MUTATION_TYPES.SET_IS_LOAD_DATA, false);
      }, 500);
    });
    shippingsRequest.onError(() => {
      window.location = 'https://www.robinfood.com';
      commit(MUTATION_TYPES.SET_IS_LOAD_DATA, false);
    });
    shippingsRequest.getShippingByUid(uid);
  },
};

// Mutations
export const mutations = {
  ///Set the state of the poll as finished so the user cannnot perform any more actions
  [MUTATION_TYPES.FINISH_POLL](state) {
    state.finished = true;
  },

  ///Set the current view id
  [MUTATION_TYPES.SET_CURRENT_VIEW_ID](state, payload) {
    state.idCurrentView = payload;
  },

  ///Set the theme configuration
  [MUTATION_TYPES.SET_POLL_THEME](state, payload) {
    state.theme.accentColor = payload[FIELDS.MAIN_COLOR];
    state.theme.backgroundColor = payload[FIELDS.COLOR];
    state.theme.backgroundImage = payload[FIELDS.IMAGE];
    state.theme.locale = payload[FIELDS.LOCALE];
    state.theme.logo = payload[FIELDS.LOGO];
    state.theme.badgeBackgroundColor = payload[FIELDS.MAIN_BASE_COLOR];
    state.theme.mainFont = payload[FIELDS.MAIN_FONT];
    state.theme.mainTextColor = payload[FIELDS.MAIN_TEXT_COLOR];
    state.theme.textColorOverAccent = payload[FIELDS.TEXT_COLOR_OVER_MAIN_COLOR];
    state.theme.secondaryFont = payload[FIELDS.SECONDARY_FONT];
    state.theme.secondaryTextColor = payload[FIELDS.SECONDARY_TEXT_COLOR];
    state.theme.secondaryColor = payload[FIELDS.SECONDARY_COLOR];
  },

  ///Set the views configuration
  [MUTATION_TYPES.SET_POLL_VIEWS](state, payload) {
    const poll = payload.polls;
    if (poll !== undefined) {
      const views = parseViewsFromJson(poll.views);
      state.views = views.sort((a, b) => (a.order || 0) > (b.order || 0));
      const allQuestions = [];
      state.views.forEach(element => {
        element.questions.forEach(question => {
          allQuestions.push(question);
        });
      });
      state.allQuestions = allQuestions;
      const firstView = state.views[0];
      state.idCurrentView = firstView !== undefined ? firstView.id : VIEWS_ID.INITIAL_VIEW_ID;
      state.viewsHistory.push(state.idCurrentView);
    } else {
      state.views = [];
    }
  },

  //Complete view as answered
  [MUTATION_TYPES.SET_VIEW_AS_ANSWERED](state, payload) {
    const { viewId, nextViewId } = payload;
    const viewToBeCompleted = state.views.find(view => view.id === viewId);
    if (viewToBeCompleted !== undefined) {
      viewToBeCompleted.setViewAsAnswered();
    }

    state.isClickToBack = false;
    state.idCurrentView = nextViewId;

    //Add or update view in historial
    const indexNext = state.previewIndexIdCurrentView + 2;
    const isNextView = indexNext <= state.viewsHistory.length - 1;
    if (isNextView) {
      if (state.viewsHistory[indexNext] !== nextViewId) {
        const newHistorial = [...state.viewsHistory.slice(0, indexNext), nextViewId];
        state.viewsHistory = newHistorial;
      }
    } else {
      state.viewsHistory.push(nextViewId);
    }

    state.previewIndexIdCurrentView++;
  },

  //Go to preview view
  [MUTATION_TYPES.SET_VIEW_BACK_ANSWER](state) {
    state.isClickToBack = true;
    state.idCurrentView = state.viewsHistory[state.previewIndexIdCurrentView];
    state.previewIndexIdCurrentView--;
  },

  ///Set poll data
  [MUTATION_TYPES.SET_POLL_DATA](state, payload) {
    state.poll = payload;
  },

  ///Set is load data
  [MUTATION_TYPES.SET_IS_LOAD_DATA](state, payload) {
    state.isLoadData = payload;
  },

  ///Set satisfaction survey url
  [MUTATION_TYPES.SET_SATISFACTION_SURVEY_URL](state, payload) {
    state.satisfactionSurveyUrl = payload;
  },
};

///Returns an anwer object from a json object
const parseAnswerFromJson = function(json) {
  return new Answer(
    json[FIELDS.ANSWER_ID],
    json[FIELDS.NEXT_VIEW_ID],
    json[FIELDS.ORDER],
    json[FIELDS.ANSWER_TYPE_ID],
    json[FIELDS.VALUE],
    json[FIELDS.NEXT_QUESTION_ID],
  );
};

///Returns a question object from a json object
const parseQuestionFromJson = function(json) {
  const answers = [];
  const answersJson = json[FIELDS.ANSWERS];
  answersJson.forEach(answer => {
    answers.push(parseAnswerFromJson(answer));
  });
  return new Question(
    answers,
    json[FIELDS.HELP_TEXT],
    json[FIELDS.QUESTION_ID],
    json[FIELDS.NAME],
    json[FIELDS.OPTIONS_SELECTED],
    json[FIELDS.QUESTION_TYPE_ID],
    json[FIELDS.VALUE],
    json[FIELDS.HELP_TEXT_EXTRA_ONE],
    json[FIELDS.HELP_TEXT_EXTRA_TWO],
  );
};

///Returns a view object from a json object
const parseViewFromJson = function(json) {
  const questions = [];
  const jsonQuestions = json[FIELDS.QUESTIONS] || [];
  jsonQuestions.forEach(question => {
    questions.push(parseQuestionFromJson(question));
  });
  return new View(
    json[FIELDS.ID],
    json[FIELDS.CHECKPOINT],
    json[FIELDS.NEXT_VIEW_ID],
    json[FIELDS.ORDER],
    questions,
    json[FIELDS.VIEW_TYPE_ID],
    json[FIELDS.VIEW_NAME],
  );
};

///Returns the list of views from a json object
const parseViewsFromJson = function(jsonViews) {
  const views = [];
  jsonViews.forEach(jsonView => {
    views.push(parseViewFromJson(jsonView));
  });
  return views;
};

const validateDiffDatesInHours = function(dateOne, dateTwo) {
  const diff = dateTwo.getTime() - dateOne.getTime();
  return diff / (1000 * 3600) > 24;
};

// Screens module
export default {
  namespaced: true,
  state: {
    idCurrentView: VIEWS_ID.INITIAL_VIEW_ID,
    previewIndexIdCurrentView: -1,
    viewsHistory: [],
    finished: false,
    isClickToBack: false,
    theme: {
      accentColor: undefined,
      backgroundColor: undefined,
      backgroundImage: undefined,
      id: 1,
      name: '',
      locale: '',
      logo: undefined,
      mainFont: undefined,
      mainTextColor: undefined,
      textColorOverAccent: undefined,
      secondaryFont: undefined,
      secondaryTextColor: undefined,
    },
    views: [],
    allQuestions: [],
    poll: null,
    isLoadData: false,
    satisfactionSurveyUrl: null,
  },
  getters,
  actions,
  mutations,
};
