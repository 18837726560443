<template>
  <div class="row">
    <div class="col-12 col-xl-10 offset-xl-1">
      <div class="px-xl-4 mb-3">
        <button
          @click="nextQuestion"
          class="btn btn-primary btn-block py-3 rounded-xl font-weight-bold"
          v-bind:style="{ background: backgroundColor, border: backgroundColor, color: color }"
        >
          {{ $t(buttonValue) }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { STORES } from '../constants/NpsConstants';

export default {
  name: 'ButtonNext',
  props: {
    color: String,
    backgroundColor: String,
    nextQuestion: Function,
    currentView: Number,
  },
  computed: {
    //Returns the text that should be shown on this component
    buttonValue() {
      return this.currentView === undefined ||
        this.$store.getters[`${STORES.SEND_POLL_INFO_STORE}/showSendTextOnButton`]
        ? 'buttonNext.send'
        : 'buttonNext.next';
    },
  },
};
</script>
