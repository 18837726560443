<template>
  <span
    class="star-svg-container"
    :class="active || hover ? 'active' : ''"
    @mouseover="onHover"
    @mouseleave="onBlur"
    @click.prevent="onClick"
  >
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 426.667 426.667"
      style="enable-background: new 0 0 426.667 426.667"
      xml:space="preserve"
    >
      <polygon
        :style="`fill: ${color}`"
        points="
            213.333,10.441 279.249,144.017 426.667,165.436 320,269.41 345.173,416.226
            213.333,346.91 81.485,416.226 106.667,269.41 0,165.436 147.409,144.017
        "
      />
    </svg>
  </span>
</template>

<script>
export default {
  name: 'Star',

  data: () => ({
    hover: false,
  }),

  props: {
    active: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: '#ffb000',
    },
  },

  methods: {
    onHover() {
      this.hover = true;
      this.$emit('hover');
    },
    onBlur() {
      this.hover = false;
      this.$emit('blur');
    },
    onClick(event) {
      this.$emit('select', event);
    },
  },
};
</script>

<style lang="scss" scoped>
.star-svg-container {
  $size: 42px;
  cursor: pointer;
  display: block;
  height: $size;
  opacity: 0.32;
  transform: scale(0.75);
  transition: all 0.2s;
  width: $size;
  svg {
    display: block;
    height: 100%;
    width: 100%;
  }
  &.active {
    opacity: 1;
    transform: scale(1);
  }
}
</style>
