<template>
  <div class="wrapper" v-bind:style="{ background: this.getTheme.backgroundColor }" v-if="!isSatisfactionSurvey">
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-6 offset-md-3">
          <div
            class="mainWrapper d-flex flex-column justify-content-between"
            v-bind:style="{ fontFamily: [this.getTheme.secondaryFont, this.getTheme.mainFont] }"
          >
            <div class="mb-md-2">
              <div class="row mb-4">
                <div class="col-12 mt-5">
                  <ButtonBack :color="this.getTheme.accentColor" :goBackToLastQuestion="goBackToLastView" />
                  <img :src="this.getLogo" alt="logo" class="img-fluid" width="100" height="100" />
                </div>
              </div>
              <!-- Dynamic Question -->
              <div class="row mb-3">
                <div class="col-12 col-lg-10 offset-lg-1 col-xl-8 offset-xl-2 text-left text-md-center">
                  <div class="px-4">
                    <h1 class="mb-3" v-bind:style="{ color: this.getTheme.mainTextColor, fontWeight: '600' }">
                      {{ $t('userData.title') }}
                    </h1>
                    <div class="d-flex justify-content-md-center negativeMargin">
                      <Steper :color="getTheme.accentColor" :defaultStep="4" />
                    </div>
                    <span v-bind:style="{ color: this.getTheme.mainTextColor, opacity: '0.57' }">
                      {{ $t('userData.message') }}</span
                    >
                    <div class="steper"></div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-xl-10 offset-xl-1">
                  <div class="px-4 px-md-0 px-xl-4">
                    <form>
                      <div class="form-group">
                        <input
                          v-show="!this.hasUserPhone"
                          v-model="cellphone"
                          type="phone"
                          class="form-control border-0 form-control-lg"
                          id="CellNumber"
                          :placeholder="$t('userData.cellphonePlaceholder')"
                          name="phone"
                        />
                        <label for="CellNumber" v-show="false" />
                      </div>
                      <ViewExampleButtonInput
                        v-show="!this.hasOrderId"
                        :mainColor="this.getTheme.mainTextColor"
                        :secondaryColor="this.getTheme.secondaryTextColor"
                        :backgroundColor="this.getTheme.backgroundColor"
                        :onFieldChanged="this.onOrderIdChanged"
                      />
                    </form>
                    <Modal
                      :accentColor="this.getTheme.accentColor"
                      :mainColor="this.getTheme.mainTextColor"
                      :secondaryTextColor="this.getTheme.secondaryTextColor"
                    />
                  </div>
                </div>
              </div>
            </div>
            <!-- Image -->
            <BackImage :image="getTheme.backgroundImage" />
            <!-- Dynamic Button -->
            <ButtonNext
              :color="this.getTheme.accentColor"
              :secondaryColor="this.getTheme.secondaryTextColor"
              :nextQuestion="nextQuestion"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex';
import { ACTION_TYPES } from '../store/action-types';
import { STORES } from '../constants/NpsConstants';
import ButtonNext from '../components/ButtonNext';
import Modal from '../components/Modal';
import ViewExampleButtonInput from '../components/ViewExampleButtonInput';
import BackImage from '../components/BackImage';
import Steper from '../components/Steper';
import ButtonBack from '@/components/ButtonBack.vue';
import { GoBackToLastQuestionMixin } from '../mixins';
// @ is an alias to /src

export default {
  name: 'UserData',

  mixins: [GoBackToLastQuestionMixin],

  data() {
    return {
      cellphone: '',
      orderId: '',
    };
  },

  components: {
    ButtonBack,
    ButtonNext,
    Modal,
    ViewExampleButtonInput,
    BackImage,
    Steper,
  },

  computed: {
    ...mapGetters(STORES.POLL_SETTINGS, [
      'getCurrentView',
      'getLogo',
      'getTheme',
      'showBottomButton',
      'isSatisfactionSurvey',
    ]),
    ...mapGetters(STORES.SEND_POLL_INFO_STORE, ['hasUserPhone', 'hasOrderId']),
  },

  methods: {
    ...mapActions([`${STORES.SEND_POLL_INFO_STORE}/${ACTION_TYPES.SET_EXTRA_DATA}`]),

    // switch to the previous view
    goBackToLastView() {
      this.goBackToLastQuestion();
      this.$router.push('/');
    },

    nextQuestion(event) {
      this.$store.dispatch(`${STORES.SEND_POLL_INFO_STORE}/${ACTION_TYPES.SET_EXTRA_DATA}`, {
        cellphone: this.cellphone,
        orderId: this.orderId,
      });
    },

    onOrderIdChanged(newOrderId) {
      this.orderId = newOrderId;
    },
  },

  mounted() {
    if (this.hasUserPhone && this.hasOrderId) {
      this.$store.dispatch(
        `${STORES.SEND_POLL_INFO_STORE}/${ACTION_TYPES.SEND_POLL}`,
        { finish: true },
        { root: true },
      );
    }
  },
};
</script>
<style lang="scss" scoped>
.negativeMargin {
  margin-left: -25px;
}
</style>
