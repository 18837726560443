/// Answer types
export const ANSWER_TYPES = {
  ANSWER_TYPE_TEXT_FIELD: 2,
  ANSWER_TYPE_ADDITIONAL_TEXT_FIELD: 3,
};

///Colors
export const COLORS = {
  BLACK: '#000000',
  WHITE: '#FFFFFF',
};

///Defaults
export const DEFAULT_EMPTY_STRING = '';
export const DEFAULT_FONT = 'Monserrat';

// Nps origins
export const NPS_ORIGIN_DEFAULT = 'Genérica';
export const NPS_ORIGIN_GENERIC = 'Genérica';
export const NPS_ORIGIN_1 = 'Factura';
export const NPS_ORIGIN_2 = 'Flyer';
export const NPS_ORIGIN_3 = 'Caja';
export const NPS_ORIGIN_4 = 'Bolsa o empaque';
export const NPS_ORIGIN_5 = 'Social media';
export const NPS_ORIGIN_6 = 'Flyer factura';
export const NPS_ORIGIN_7 = 'Landing page';
export const NPS_ORIGIN_8 = 'Cintillo';

export const NPS_ORIGINS = {
  Genérica: NPS_ORIGIN_GENERIC,
  '1': NPS_ORIGIN_1,
  '2': NPS_ORIGIN_2,
  '3': NPS_ORIGIN_3,
  '4': NPS_ORIGIN_4,
  '5': NPS_ORIGIN_5,
  '6': NPS_ORIGIN_6,
  '7': NPS_ORIGIN_7,
  '8': NPS_ORIGIN_8,
};

//Origin id's
export const ORIGIN_ID = {
  SMS: 9,
};

//Question type id
export const QUESTION_TYPE = {
  RANGE: 1,
  MULTIPLE: 2,
  OPEN: 3,
  YESNO: 4,
  EXIT_POLL: 5,
  STARS: 6,
};

///Stores
export const STORES = {
  POLL_SETTINGS: 'PollSettingsStore',
  SEND_POLL_INFO_STORE: 'SendPollInfoStore',
};

///Users groups
export const USERS_GROUPS = {
  DETRACTOR: 'Detractor',
  PASSIVE: 'Pasivo',
  PROMOTOR: 'Promotor',
};

//Version
export const VERSION = '1.0.5';

///Views
export const VIEWS_ID = {
  INITIAL_VIEW_ID: -1,
  FINISH_POLL_VIEW_ID: -2,
  UNIQUE_POLL_VIEW_ID: -3,
  REDIRECT_VIEW_ID: -10,
};

//View types
export const VIEW_TYPES = {
  VIEW_DECIDES_NEXT: 1,
  ANSWERS_DECIDES_NEXT: 2,
  ANSWERS_DECIDES_NEXT_NO_BOTTOM_BUTTON: 4,
  EXIT_POLL: 5,
};

export const ARRAY_NAME_CURRENT_QUESTIONS = 'questions';
export const ARRAY_NAME_QUESTIONS_ADD = 'questionsToAdd';
