<template>
  <div class="row">
    <div class="col-12 col-xl-10 offset-xl-1">
      <div class="px-xl-4">
        <p class="error-message px-2 py-2 mb-2 rounded-xl font-weight-lighter" v-if="this.validationError">
          {{ $t(this.validationError) }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: 'ErrorMessage',
  props: {
    validationError: String,
  },
};
</script>

<style scoped>
.error-message {
  background-color: #1c1c1c;
  color: white;
  font-size: 0.8rem;
  font-family: 'Open Sans', sans-serif;
}
</style>
