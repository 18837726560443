import amplitude from 'amplitude-js';
import { NPS_ORIGIN_DEFAULT, NPS_ORIGINS, USERS_GROUPS } from '../constants/NpsConstants';
const { VUE_APP_AMPLITUDE_API_KEY } = process.env;
// This class handle all the analytics events, the methods were made in a general way,
// so the can be filled and used with another analytics libraries.
// Every method has a short description of what it does, and its parameters has explicit name for description purpose.
// Any other new method with specific values required could be added by adding those values to the propertyMap object.
// For any further information, you can contact the author Mauricio Orozco, Fron-End Developer.
class AnalyticsManager {
  constructor() {
    // Amplitude proper method to call and connect the instance
    amplitude.getInstance().init(VUE_APP_AMPLITUDE_API_KEY);
  }

  // Helper to return an specific answer value
  answerHelper(viewQuestions, questionType, state, callback) {
    const validQuestion = viewQuestions.type === questionType;
    const specificQuestion = validQuestion && viewQuestions;
    const specificAnswer =
      specificQuestion && state.questions.find(answer => answer.questionId === specificQuestion.id);
    specificAnswer && callback(specificAnswer.answers[0].value, this, validQuestion);
  }

  // Get the current date and format it into the required way
  getCurrentDate() {
    const today = new Date();
    let mm = today.getMonth() + 1;
    let dd = today.getDate();

    if (mm < 10) {
      mm = `0${mm}`;
    }
    if (dd < 10) {
      dd = `0${dd}`;
    }
    const date = `${today.getFullYear()}-${mm}-${dd}`;
    const time = `${today.getHours()}:${today.getMinutes()}:${today.getSeconds()}`;
    return `${date} ${time}`;
  }

  // Method to get the nps name and origin on the poll start
  getNpsInitialValues(npsName, npsOrigin) {
    this.npsName = npsName;
    this.npsOrigin = npsOrigin || NPS_ORIGIN_DEFAULT;
    this.originName = NPS_ORIGINS[this.npsOrigin];
  }

  // General method to send any implemented event to amplitude.
  // This method has two default values (date and npsName) that are shared within all events
  sendEvent(event, propertyMap) {
    propertyMap.date = this.getCurrentDate();
    if (this.npsName) {
      propertyMap.npsName = this.npsName;
    }
    if (this.npsOrigin) {
      propertyMap.origin = this.npsOrigin;
    }
    if (this.originName) {
      propertyMap.originName = this.originName;
    }
    amplitude.getInstance().logEvent(event, propertyMap);
  }

  // When back button is pressed
  sendBackButtonPressed(viewName) {
    const propertyMap = {
      viewName,
    };
    this.sendEvent('back_button_pressed', propertyMap);
  }

  // Send the exit poll answer (question type 5)
  sendExitPollAnswer(exitPollAnswer) {
    const propertyMap = {
      exitPollAnswer,
    };
    this.sendEvent('survey_satisfaction_close', propertyMap);
  }

  // When the poll ends, it check if the user answer the exit poll
  sendNpsFinishedButtonTapped(hasExitPoll) {
    const propertyMap = {
      hasExitPoll,
    };
    this.sendEvent('nps_finished_button_tapped', propertyMap);
  }

  // When the poll ends but the service had an error.
  sendNpsFinishedFailed(hasExitPoll, errorMessage, statusCode) {
    const propertyMap = {
      hasExitPoll,
      errorMessage,
      statusCode,
    };
    this.sendEvent('nps_finished_failed', propertyMap);
  }

  // When the poll ends and the service got status 200.
  sendNpsFinishedSuccess(hasExitPoll) {
    const propertyMap = {
      hasExitPoll,
    };
    this.sendEvent('survey_satisfaction_sent', propertyMap);
  }

  // When the nps start
  sendNpsOpened() {
    const propertyMap = {};
    this.sendEvent('survey_satisfaction_open_dialog', propertyMap);
  }

  // When the user answer the range question.
  sendNpsRangeQuestionAnswer(rangeAnswer) {
    let userGroup = null;
    if (rangeAnswer < 7) {
      userGroup = USERS_GROUPS.DETRACTOR;
    } else if (rangeAnswer > 6 && rangeAnswer < 9) {
      userGroup = USERS_GROUPS.PASSIVE;
    } else if (rangeAnswer > 8) {
      userGroup = USERS_GROUPS.PROMOTOR;
    }
    const propertyMap = {
      rangeAnswer,
      userGroup,
    };
    this.sendEvent('nps_range_question_answer', propertyMap);
  }

  // When service suffers a crash.
  sendServiceError(serviceName, serviceError, statusCode) {
    const propertyMap = {
      serviceName,
      serviceError,
      statusCode,
    };
    this.sendEvent('service_error', propertyMap);
  }

  // When next button is tapped, the viewStep is a counter increased with every button click.
  sendViewNextButtonTapped(viewName, viewStep) {
    const propertyMap = {
      viewName,
      viewStep,
    };
    this.sendEvent('view_next_button_tapped', propertyMap);
  }

  // When user answer a question and a new one is shown.
  sendViewOpened(viewName) {
    const propertyMap = {
      viewName,
    };
    this.sendEvent('view_opened', propertyMap);
  }
}

export default new AnalyticsManager();
