<template>
  <div class="modal fade" id="qrModal" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content rounded-xl">
        <div class="modal-body text-center d-flex justify-content-between flex-column pt-4 px-5">
          <div class="col-12 col-md-8 offset-md-2">
            {{ $t('modal.title') }}
          </div>
          <div class="card my-3 rounded-xl">
            <div class="card-body pb-0">
              <div class="orderNumber text-center d-flex flex-column justify-content-center">
                <span>{{ $t('modal.order') }} 1234</span>
              </div>
              <img
                src="https://cdn.zeplin.io/5e860be01b6abf4500e6f5e0/assets/7c01307b-2e02-4b81-88a6-9f6fc47e9ceb.png"
                :alt="$t('modal.qrAlt')"
                width="180"
                height="180"
              />
              <div>
                <strong>
                  {{ $t('modal.footer') }}
                </strong>
              </div>
            </div>
          </div>
          <button
            type="button"
            class="btn btn-block rounded-xl"
            data-dismiss="modal"
            v-bind:style="{
              background: accentColor,
              border: accentColor,
              color: secondaryTextColor,
              fontWeight: 'bold',
            }"
          >
            {{ $t('modal.button') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: 'Modal',
  props: {
    secondaryTextColor: String,
    accentColor: String,
    mainColor: String,
  },
};
</script>

<style lang="scss" scoped>
h6 {
  font-size: 1rem;
}
p {
  font-size: 0.8rem;
}
.modal-dialog {
  max-width: 450px;
}
.orderNumber {
  height: 85px;
  background: url('https://cdn.zeplin.io/5e860be01b6abf4500e6f5e0/assets/547d2e25-eaf2-4c59-9112-424200abf01d.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
</style>
