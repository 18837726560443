<template>
  <lottie :options="defaultOptions" :height="100" :width="100" v-on:animCreated="handleAnimation" />
</template>

<script>
import * as animationData from '@/assets/loader.json';
export default {
  name: 'Loader',
  data() {
    return {
      defaultOptions: { animationData: animationData.default },
      animationSpeed: 1,
    };
  },
  methods: {
    handleAnimation(anim) {
      this.anim = anim;
    },
  },
};
</script>
