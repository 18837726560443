<template>
  <div class="row" :class="{ 'poll-question-4__satisfaction': isSatisfactionSurvey }">
    <div class="col-12 col-xl-10 offset-xl-1">
      <div class="px-4 px-md-0 px-xl-4">
        <form>
          <div class="form-group" v-for="(option, index) in answersOptions" v-bind:key="index">
            <TextAreaComponent
              :answer="option"
              :question="question"
              :handleTextArea="handleTextArea"
              :isSatisfactionSurvey="isSatisfactionSurvey"
            />
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import TextAreaComponent from '@/components/TextAreaComponent';

export default {
  name: 'QuestionType4',
  components: {
    TextAreaComponent,
  },

  props: {
    mainColor: String,
    secondaryColor: String,
    accentColor: String,
    handleInputs: Function,
    question: Object,
    answersOptions: Array,
    handleTextArea: Function,
    isSatisfactionSurvey: Boolean,
  },
};
</script>

<style lang="scss" scoped>
.poll-question-4__satisfaction {
  .form-group {
    margin-bottom: 0;
  }
}
</style>
