<template>
  <div :class="{ 'poll-question-textarea__satisfaction': isSatisfactionSurvey }">
    <textarea
      v-model="message"
      name="answer"
      class="form-control border-0 rounded-xl"
      rows="4"
      :placeholder="question.helpText"
      :id="'textarea' + question.id"
    ></textarea>
    <label :for="'textarea' + question.id" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { STORES } from '../constants/NpsConstants';
import { AdditionalFieldMixin } from '../mixins';
import { MUTATION_TYPES } from '../store/mutation-types';
// @ is an alias to /src
export default {
  name: 'TextAreaComponent',

  mixins: [AdditionalFieldMixin],

  data() {
    return {
      isNotCallHandleTextArea: false,
      message: '',
    };
  },

  props: {
    answer: Object,
    clearField: Boolean,
    question: Object,
    handleTextArea: Function,
    isSatisfactionSurvey: Boolean,
  },

  watch: {
    clearField(newValue) {
      if (newValue === true) {
        this.message = '';
      }
    },

    message(newValue) {
      if (this.isNotCallHandleTextArea) {
        this.isNotCallHandleTextArea = false;
      } else {
        this.handleTextArea(this.answer, this.question.id, newValue);
      }
    },
  },

  computed: {
    ...mapGetters(STORES.SEND_POLL_INFO_STORE, ['getQuestionAsweredById']),
  },

  methods: {
    // change the message displayed in the textarea
    changeMessage(message) {
      this.message = message;
    },

    // Load previous response of the textarea component
    loadAnswer() {
      const selectedQuestion = this.getQuestionAsweredById(this.question.id);
      if (selectedQuestion) {
        if (selectedQuestion.answers.length > 1) {
          const findAnswer = this.findAdditionalAnswer(selectedQuestion.answers);
          if (findAnswer) {
            this.preventTextAreaEvent();
            this.changeMessage(findAnswer.value);
          }
        } else {
          this.$store.commit(`${STORES.SEND_POLL_INFO_STORE}/${MUTATION_TYPES.ADD_QUESTION}`, selectedQuestion);
          this.preventTextAreaEvent();
          this.changeMessage(selectedQuestion.answers[0].value);
        }
      }
    },

    // prevents the handleTextArea method from being invoked when the message is initialized
    preventTextAreaEvent() {
      this.isNotCallHandleTextArea = true;
    },
  },

  mounted() {
    this.loadAnswer();
  },
};
</script>

<style lang="scss" scoped>
.poll-question-textarea__satisfaction {
  label {
    display: none;
  }
  textarea {
    border: solid 1px rgba(0, 0, 0, 0.12) !important;
    font-family: Montserrat, sans-serif;
    font-size: 0.875rem;
    font-weight: 300;
    height: 80px;
  }
}
</style>
