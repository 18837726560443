import Vue from 'vue';
import VueRouter from 'vue-router';
import FinishedSurvey from '../views/FinishedSurvey.vue';
import Home from '../views/Home.vue';
import UniquePollErrorView from '../views/UniquePollErrorView.vue';
import UserData from '../views/UserData.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/finished',
    name: 'finished',
    component: FinishedSurvey,
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/resolved',
    name: 'resolved',
    component: UniquePollErrorView,
  },
  {
    path: '/data',
    name: 'data',
    component: UserData,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
