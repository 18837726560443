import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './lang';
import Lottie from 'vue-lottie';
import AnalyticsManager from './analytics/analytics';

Vue.component('lottie', Lottie);
Vue.config.productionTip = false;
Vue.prototype.$analytics = AnalyticsManager;

new Vue({
  router,
  store,
  i18n,
  Lottie,
  render: h => h(App),
}).$mount('#app');
