import { ACTION_TYPES } from '../action-types.js';
import { FIELDS } from '../../constants/ApiConstants.js';
import { MUTATION_TYPES } from '../mutation-types.js';
import sendPollRequest from '../../http/requests/SendPollRequest.js';
import AnalyticsManager from '../../analytics/analytics';
import {
  ANSWER_TYPES,
  ARRAY_NAME_CURRENT_QUESTIONS,
  ARRAY_NAME_QUESTIONS_ADD,
  DEFAULT_EMPTY_STRING,
  ORIGIN_ID,
  QUESTION_TYPE,
  STORES,
  VIEWS_ID,
  VIEW_TYPES,
} from '../../constants/NpsConstants';
import { POLL_TYPES } from '../../constants/PollConstants.js';

// Getters
export const getters = {
  ///Get current view
  // eslint-disable-next-line
  getCurrentView(state, pGetters, rootState, rootGetters) {
    return rootGetters[`${STORES.POLL_SETTINGS}/getCurrentView`];
  },

  //Gets the id of the origin for the poll
  getOriginId(state) {
    return state.originId;
  },

  //Gets the poll step
  getPollStep(state) {
    return state.pollStep;
  },

  //Gets questions answered
  getQuestionViewAnswered(state) {
    return state.questions;
  },

  //Get question answered by ID
  getQuestionAsweredById: state => questionId =>
    state.questionsAnswered.find(questionAnswered => questionAnswered.questionId === questionId),

  //Get validation error if ant
  getValidationError(state) {
    return state.validationError;
  },

  ///True if the order id is already on the meta data
  hasOrderId(state) {
    const { orderId, uid } = state;
    if (orderId || uid) {
      return true;
    } else {
      return false;
    }
  },

  ///True if the user phone number is already on the meta data
  hasUserPhone(state) {
    const { uid, userNumber } = state;
    if (userNumber || uid) {
      return true;
    } else {
      return false;
    }
  },

  //Method that returns true if the send button should show “Send“ text, it returns false otherwise
  showSendTextOnButton(state, getters) {
    const nextView = getters.getCurrentView.nextViewId;
    const { orderId, uid, userNumber } = state;
    if (nextView === VIEWS_ID.FINISH_POLL_VIEW_ID && (uid || (orderId && userNumber))) {
      return true;
    } else {
      return false;
    }
  },

  //Get message finished
  getMessageFinished(state) {
    return state.messageFinished;
  },

  //Get shipping as answered
  getShippingAsAnswered(state) {
    return state.shippingAsAnswered;
  },
};

// Actions
const actions = {
  //Answer questions on view
  [ACTION_TYPES.ANSWER_QUESTIONS_FROM_VIEW]({ commit, getters, state, dispatch }) {
    const currentView = getters.getCurrentView;
    let error = undefined;
    if (currentView) {
      currentView.questions.forEach(questionOfView => {
        if (state.questions.length === 0) {
          if (
            !(
              this.state.PollSettingsStore.poll.poll_type_id === POLL_TYPES.SATISFACTION &&
              ((questionOfView.type === QUESTION_TYPE.RANGE && questionOfView.optionsSelected === 0) ||
                questionOfView.type === QUESTION_TYPE.OPEN)
            )
          ) {
            error = {
              message: 'error.noQuestionsInViewAnswered',
            };
          }
        } else {
          let found = false;
          state.questions.forEach(answeredQuestion => {
            if (
              questionOfView.type === ANSWER_TYPES.ANSWER_TYPE_ADDITIONAL_TEXT_FIELD ||
              questionOfView.id === answeredQuestion.questionId
            ) {
              found = true;
              if (
                answeredQuestion.answers.length === 0 &&
                questionOfView.type !== ANSWER_TYPES.ANSWER_TYPE_ADDITIONAL_TEXT_FIELD
              ) {
                error = {
                  message: 'error.noAnswersInQuestion',
                };
              }
              ///Check that answers of type "other" that requires something typed by the user
              answeredQuestion.answers.forEach(answer => {
                if (answer.type === ANSWER_TYPES.ANSWER_TYPE_ADDITIONAL_TEXT_FIELD) {
                  if (answer.value.trim().length < 3) {
                    error = {
                      message: 'error.otherFieldSelectedWithoutTextProvided',
                    };
                  }
                }
              });

              if (
                this.state.PollSettingsStore.poll.poll_type_id === POLL_TYPES.SATISFACTION &&
                questionOfView.type === QUESTION_TYPE.OPEN &&
                questionOfView.id === answeredQuestion.questionId
              ) {
                answeredQuestion.answers.forEach(answer => {
                  const valueText = answer.value.trim();
                  if (valueText !== '' && (valueText.length < 50 || valueText.length > 250)) {
                    error = {
                      message: 'error.optionalCommentSatisfactionSurvey',
                    };
                  }
                });
              }
            }
          });
          if (!found) {
            if (this.state.PollSettingsStore.poll.poll_type_id === POLL_TYPES.SATISFACTION) {
              const questionsInView = currentView.questions.filter((question, index) => {
                if (index === 0) return true;
                return !!state.questions.find(questionAnswered => {
                  return !!questionAnswered.answers.find(answer => answer.nextQuestionId === question.id);
                });
              });
              if (questionsInView.find(questionsInView => questionsInView.id === questionOfView.id)) {
                error = {
                  message: 'error.noAnswersInQuestion',
                };
              }
            } else {
              error = {
                message: 'error.noQuestionsInViewAnswered',
              };
            }
          }
        }
      });
    }
    const viewId = currentView !== undefined ? currentView.id : VIEWS_ID.INITIAL_VIEW_ID;
    let nextViewId;
    if (error === undefined) {
      if (currentView !== undefined) {
        const questions = state.questions;
        if (
          (currentView.typeId === VIEW_TYPES.ANSWERS_DECIDES_NEXT ||
            currentView.typeId === VIEW_TYPES.ANSWERS_DECIDES_NEXT_NO_BOTTOM_BUTTON) &&
          currentView.questions.length > 0 &&
          questions.length > 0 &&
          questions[0].answers.length > 0
        ) {
          const answer = questions.find(element => element.questionId === currentView.questions[0].id).answers[0];
          nextViewId = answer.nextViewId;
        } else {
          nextViewId = currentView.nextViewId;
        }
      }
      //This next section of code, hides exit poll in case the user comes
      //from the origin of SMS, since the exit poll should have been
      //already answered on another flow.
      let views = this.getters[`${STORES.POLL_SETTINGS}/getViews`];
      let nextView = views.find(view => view.id === nextViewId);
      if (
        nextView !== undefined &&
        nextView.questions !== undefined &&
        nextView.questions.length > 0 &&
        nextView.questions[0].type === QUESTION_TYPE.EXIT_POLL &&
        parseInt(state.originId) === ORIGIN_ID.SMS
      ) {
        nextViewId = nextView.nextViewId;
      }

      //Disappear error
      commit(`${MUTATION_TYPES.SET_VALIDATION_ERROR}`, undefined);

      //Send answers to services if view is checkpoint or is the last view of poll
      if (currentView) {
        const isViewCheckpoint = currentView.isViewCheckpoint() && nextViewId !== VIEWS_ID.FINISH_POLL_VIEW_ID;
        const isFinishView =
          nextViewId === VIEWS_ID.FINISH_POLL_VIEW_ID && !getters.hasUserPhone && !getters.hasOrderId;
        if (isViewCheckpoint || isFinishView) {
          dispatch(`${STORES.SEND_POLL_INFO_STORE}/${ACTION_TYPES.SEND_POLL}`, { finish: false }, { root: true });
        }
      }

      //Set poll step
      commit(`${MUTATION_TYPES.SET_POLL_STEP}`);

      //Analytic event for next view button tapped
      if (!(currentView.questions[0].type === QUESTION_TYPE.EXIT_POLL && state.uid)) {
        AnalyticsManager.sendViewNextButtonTapped(currentView.viewName, getters.getPollStep);
      }

      //Set view as answered
      commit(`${STORES.POLL_SETTINGS}/${MUTATION_TYPES.SET_VIEW_AS_ANSWERED}`, { viewId, nextViewId }, { root: true });

      //Analytics events for range and exit poll answer
      let viewQuestions;
      if (currentView) {
        viewQuestions = currentView.questions[0];
      }

      AnalyticsManager.answerHelper(
        viewQuestions,
        VIEW_TYPES.VIEW_DECIDES_NEXT,
        state,
        (answer, that) => answer && that.sendNpsRangeQuestionAnswer(answer),
      );

      AnalyticsManager.answerHelper(
        viewQuestions,
        VIEW_TYPES.EXIT_POLL,
        state,
        (answer, that, question) => (
          answer && that.sendExitPollAnswer(answer), commit(`${MUTATION_TYPES.SET_EXIT_POLL}`, question)
        ),
      );
    } else {
      commit(`${MUTATION_TYPES.SET_VALIDATION_ERROR}`, error.message);

      //Remove error message after 4 seconds
      setTimeout(function() {
        commit(`${MUTATION_TYPES.SET_VALIDATION_ERROR}`, undefined);
      }, 4000);
    }
  },

  //Send poll
  [ACTION_TYPES.SEND_POLL]({ state, commit }, payload) {
    const { orderId, originId, pollId, storeId, uid, userId, userName, userCodeNumber, userNumber, questions } = state;
    const { finish } = payload;
    const body = {
      [FIELDS.ORIGIN_ID]: originId || -1,
      [FIELDS.POLL_ID]: pollId,
      [FIELDS.QUESTIONS]: parseQuestionsToJson(questions),
      [FIELDS.STORE_ID]: storeId,
      [FIELDS.SOURCE_ID]: orderId || DEFAULT_EMPTY_STRING,
      [FIELDS.UID]: uid || DEFAULT_EMPTY_STRING,
      [FIELDS.USER]: {
        [FIELDS.USER_ID]: userId || -1,
        [FIELDS.USER_NAME]: userName,
        [FIELDS.PHONE_CODE]: userCodeNumber,
        [FIELDS.PHONE_NUMBER]: userNumber,
      },
    };

    if (state.userPollId) {
      body[FIELDS.USER_POLL_ID] = state.userPollId;
    }

    // Analytics event
    if (finish) {
      AnalyticsManager.sendNpsFinishedButtonTapped(state.hasExitPoll);
    }

    sendPollRequest.onData((data, code, action) => {
      if (code === 203 && action === -1 && !finish) {
        commit(`${STORES.POLL_SETTINGS}/${MUTATION_TYPES.SET_CURRENT_VIEW_ID}`, VIEWS_ID.UNIQUE_POLL_VIEW_ID, {
          root: true,
        });
      } else {
        const userPollId = data[FIELDS.USER_POLL_ID];
        commit(`${STORES.SEND_POLL_INFO_STORE}/${MUTATION_TYPES.SET_USER_POLL_ID}`, { userPollId }, { root: true });
        commit(`${STORES.SEND_POLL_INFO_STORE}/${MUTATION_TYPES.CLEAR_QUESTIONS}`, null, { root: true });
        commit(`${STORES.SEND_POLL_INFO_STORE}/${MUTATION_TYPES.MOVE_QUESTIONS_TO_ADD}`, null, { root: true });

        commit(`${MUTATION_TYPES.SET_SHIPPING_AS_ANSWERED}`, true);

        if (finish) {
          commit(`${STORES.POLL_SETTINGS}/${MUTATION_TYPES.FINISH_POLL}`, null, { root: true });
          // Analytics event
          AnalyticsManager.sendNpsFinishedSuccess(state.hasExitPoll);
        }
      }
    });

    sendPollRequest.onError((error, code) => {
      if (code === 203 && error.action === -1) {
        commit(`${STORES.POLL_SETTINGS}/${MUTATION_TYPES.SET_CURRENT_VIEW_ID}`, VIEWS_ID.UNIQUE_POLL_VIEW_ID, {
          root: true,
        });
      } else if (finish) {
        commit(`${STORES.POLL_SETTINGS}/${MUTATION_TYPES.FINISH_POLL}`, null, { root: true });
        // Analytics event
        AnalyticsManager.sendNpsFinishedFailed(state.hasExitPoll, error, code);
      }
    });

    sendPollRequest.sendPoll(body);
  },

  //Set user phone
  [ACTION_TYPES.SET_EXTRA_DATA]({ commit, dispatch }, payload) {
    const { cellphone, orderId } = payload;
    commit(`${MUTATION_TYPES.SET_USER_PHONE}`, cellphone);
    commit(`${MUTATION_TYPES.SET_ORDER_ID}`, orderId);
    dispatch(`${STORES.SEND_POLL_INFO_STORE}/${ACTION_TYPES.SEND_POLL}`, { finish: true }, { root: true });
  },
};

// Mutations
export const mutations = {
  //Answer question
  [MUTATION_TYPES.ADD_ANSWERED_QUESTION](state, payload) {
    const { questionId, answer, answerValue, replace } = payload;
    answer.value = !(answerValue === null || answerValue === undefined) ? answerValue : answer.value;
    const question = state.questions.find(element => element.questionId === questionId);
    if (question) {
      const index = state.questions.indexOf(question);
      const questionFromService = this.state.PollSettingsStore.allQuestions.find(element => element.id === questionId);
      const answersSize = question.answers.length;
      const amountOfAnswers = questionFromService.optionsSelected;
      if (amountOfAnswers <= 1) {
        state.questions[index].answers = [answer];
      } else if (question.answers.find(element => element.id === answer.id)) {
        const answerIndex = question.answers.indexOf(answer);
        if (replace) {
          state.questions[index].answers[answerIndex].value = answer.value;
        } else {
          state.questions[index].answers.splice(answerIndex, 1);
        }
      } else if (answersSize < amountOfAnswers) {
        if (answer.type === ANSWER_TYPES.ANSWER_TYPE_ADDITIONAL_TEXT_FIELD) {
          answer.value = '';
        }
        state.questions[index].answers.push(answer);
      }
    } else {
      if (answer.type === ANSWER_TYPES.ANSWER_TYPE_ADDITIONAL_TEXT_FIELD) {
        answer.value = '';
      }
      state.questions.push({
        questionId,
        answers: [answer],
      });
    }
    if (this?.state.PollSettingsStore.poll.poll_type_id === POLL_TYPES.SATISFACTION) {
      const questions = this.getters[`${STORES.POLL_SETTINGS}/getCurrentView`].questions || [];
      const questionsInView = questions.filter((question, index) => {
        if (index === 0) return true;
        return !!state.questions.find(questionAnswered => {
          return !!questionAnswered.answers.find(answer => answer.nextQuestionId === question.id);
        });
      });
      state.questionsAnswered = state.questionsAnswered.filter(
        questionAnswered => !questionsInView.find(questionInView => questionInView.id === questionAnswered.questionId),
      );
      state.questions = state.questions.filter(
        questionAnswered => !!questionsInView.find(question => questionAnswered.questionId === question.id),
      );
    }
  },

  //Add question (question to be sent)
  [MUTATION_TYPES.ADD_QUESTION](state, question) {
    const isClickToBack = this.getters[`${STORES.POLL_SETTINGS}/getIsClickToBack`];
    const model = isClickToBack ? ARRAY_NAME_CURRENT_QUESTIONS : ARRAY_NAME_QUESTIONS_ADD;
    const findIndex = state[model].findIndex(questionsAnswered => questionsAnswered.questionId === question.questionId);
    if (findIndex > -1) {
      state[model][findIndex] = question;
    } else {
      state[model].push(question);
    }
  },

  //Clear answered questions
  [MUTATION_TYPES.CLEAR_QUESTIONS](state) {
    state.questions.forEach(question => {
      const findIndex = state.questionsAnswered.findIndex(
        questionAnswered => questionAnswered.questionId === question.questionId,
      );
      if (findIndex > -1) {
        // answers to questions are updated
        state.questionsAnswered[findIndex] = { ...question };
      } else {
        // answers to questions are stored
        state.questionsAnswered.push({ ...question });
      }
    });
    state.questions = [];
  },

  // Move the questions that have been answered in the current view
  // to the current question array
  [MUTATION_TYPES.MOVE_QUESTIONS_TO_ADD](state) {
    if (state.questionsToAdd.length > 0) {
      state.questions = [...state.questionsToAdd];
      state.questionsToAdd = [];
    }
  },

  // Poll initialize
  [MUTATION_TYPES.POLL_INITIALIZED](state, payload) {
    AnalyticsManager.getNpsInitialValues(payload, state.originId);
    AnalyticsManager.sendNpsOpened(state.originId);
  },

  // Set exit poll
  [MUTATION_TYPES.SET_EXIT_POLL](state, payload) {
    state.hasExitPoll = payload;
  },

  ///Set order id
  [MUTATION_TYPES.SET_ORDER_ID](state, payload) {
    state.orderId = payload;
  },

  //Set poll id
  [MUTATION_TYPES.SET_POLL_ID](state, payload) {
    state.pollId = payload;
  },

  //Set params
  [MUTATION_TYPES.SET_POLL_INFO](state, payload) {
    const {
      phone_code,
      phone_number,
      origin_id,
      order_id,
      questions = [],
      questionsAnswered = [],
      questionsToAdd = [],
      store_id,
      user_id,
      user_name,
      uid,
      source_id,
    } = payload;
    state.originId = origin_id !== undefined ? origin_id : order_id;
    state.shippingsUid = uid;
    state.storeId = store_id;
    state.uid = uid;
    state.userId = user_id;
    state.userCodeNumber = phone_code;
    state.userName = user_name;
    state.userNumber = phone_number;
    state.orderId = source_id;
    state.questions = questions;
    state.questionsToAdd = questionsToAdd;
    state.questionsAnswered = questionsAnswered;
  },

  //Set poll step
  [MUTATION_TYPES.SET_POLL_STEP](state, payload = 1) {
    state.pollStep += payload;
  },

  ///Set user phone
  [MUTATION_TYPES.SET_USER_PHONE](state, payload) {
    state.userNumber = payload;
  },

  ///Sets the user poll id to update the same poll on service call
  [MUTATION_TYPES.SET_USER_POLL_ID](state, payload) {
    const { userPollId } = payload;
    if (!state.userPollId) {
      state.userPollId = userPollId;
    }
  },

  // Set validation error
  [MUTATION_TYPES.SET_VALIDATION_ERROR](state, payload) {
    state.validationError = payload;
  },

  // Set message finished
  [MUTATION_TYPES.SET_SEND_INFO_MESSAGE_FINISHED](state, payload) {
    state.messageFinished = payload;
  },

  // Set answered shipping
  [MUTATION_TYPES.SET_SHIPPING_AS_ANSWERED](state, payload) {
    state.shippingAsAnswered = payload;
  },
};

export const parseQuestionsToJson = function(questions) {
  const questionsJson = [];
  questions.forEach(question => {
    questionsJson.push(parseQuestionToJson(question));
  });
  return questionsJson;
};

export const parseQuestionToJson = question => {
  const answersJson = [];
  question.answers.forEach(answer => {
    answersJson.push({
      [FIELDS.ANSWER_ID]: answer.id,
      [FIELDS.VALUE]: answer.value,
    });
  });
  return {
    [FIELDS.QUESTION_ID]: question.questionId,
    [FIELDS.ANSWERS]: answersJson,
  };
};

// Screens module
export default {
  namespaced: true,
  state: {
    hasExitPoll: false,
    originId: undefined,
    orderId: undefined,
    pollId: undefined,
    pollStep: 0,
    questions: [],
    questionsToAdd: [],
    questionsAnswered: [],
    shippingsUid: undefined,
    storeId: undefined,
    uid: undefined,
    userCodeNumber: undefined,
    userId: undefined,
    userName: undefined,
    userNumber: undefined,
    userPollId: undefined,
    validationError: undefined,
    messageFinished: {
      title: null,
      description: null,
    },
    shippingAsAnswered: false,
  },
  getters,
  actions,
  mutations,
};
