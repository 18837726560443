<template>
  <div class="row mb-md-5">
    <div class="col-12 col-xl-10 offset-xl-1">
      <div class="px-xl-4">
        <div class="btn-toolbar mb-2" role="toolbar">
          <div class="btn-group w-100" role="group">
            <button
              v-for="(option, index) in answersOptions"
              v-bind:key="index"
              @click="selectOption($event, option, question.id)"
              :style="styleObject"
              type="button"
              class="btn btn-answer-option"
              :class="optionIsActive(option.value) ? 'active' : ''"
            >
              {{ option.value }}
            </button>
          </div>
        </div>
        <div class="d-flex justify-content-between" :style="{ color: mainColor }">
          <strong>{{ this.question.initialHelpText }}</strong>
          <strong>{{ this.question.finalHelpText }}</strong>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { InitialValuesMixin, OptionMixin } from '../../../mixins';

// @ is an alias to /src
export default {
  name: 'QuestionType1',

  mixins: [InitialValuesMixin, OptionMixin],

  props: {
    accentColor: String,
    answersOptions: Array,
    currentView: Object,
    question: Object,
    mainColor: String,
    secondaryColor: String,
    backgroundColor: String,
    selectOption: Function,
  },

  computed: {
    styleObject() {
      return {
        '--mainColor': this.mainColor,
        '--secondaryColor': this.secondaryColor,
        '--color-hover': this.accentColor || this.backgroundColor,
      };
    },
  },

  mounted() {
    this.currentView && this.$analytics.sendViewOpened(this.currentView.viewName);
  },
};
</script>

<style scoped>
.btn-answer-option {
  color: var(--mainColor);
  opacity: 0.32;
  font-weight: bolder;
}
.btn:hover,
.active,
.focus {
  color: var(--color-hover);
  cursor: pointer;
  opacity: 1;
  outline: none !important;
  box-shadow: none;
}
</style>
