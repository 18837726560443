<template>
  <div class="row" :class="{ 'poll-question-2__satisfaction': isSatisfactionSurvey }">
    <div class="col-12 col-xl-10 offset-xl-1">
      <div class="px-4 px-md-0 px-xl-4 text-left mb-2" v-if="!isSatisfactionSurvey">
        <strong v-bind:style="{ color: mainColor }">{{ question.helpText || $t('helpTexts.pills') }}</strong>
      </div>
      <div class="poll-question-2-badges__satisfaction px-4 px-md-0 px-xl-4 text-left">
        <span
          v-for="(option, index) in answersOptions"
          v-bind:key="index"
          @click="onPillClicked($event, option, question)"
          :style="styleObject"
          class="badge badge-pill py-2 px-3 shadow-custom"
          :class="{ active: optionIsActive(option.value) }"
          >{{ answersText[index] }}</span
        >
      </div>
      <div v-if="showTextarea" class="px-4 px-md-0 px-xl-4 text-left mt-3">
        <span v-bind:style="{ color: mainColor, opacity: '0.57' }">{{ $t('helpTexts.other') }}</span>
        <TextAreaComponent
          :answer="answerTextarea"
          class="mt-2"
          :clearField="cleanTextarea"
          :question="question"
          :handleTextArea="handleTextArea"
        />
      </div>
    </div>
  </div>
</template>

<script>
import TextAreaComponent from '@/components/TextAreaComponent';
import { ANSWER_TYPES } from '../../../constants/NpsConstants';
import { AdditionalFieldMixin, InitialValuesMixin, OptionMixin } from '../../../mixins';
// @ is an alias to /src
export default {
  name: 'QuestionType2',

  mixins: [AdditionalFieldMixin, InitialValuesMixin, OptionMixin],

  components: {
    TextAreaComponent,
  },

  props: {
    accentColor: String,
    answersOptions: Array,
    backgroundColor: String,
    badgeBackgroundColor: String,
    handleTextArea: Function,
    mainColor: String,
    question: Object,
    secondaryColor: String,
    selectOptionPill: Function,
    isSatisfactionSurvey: Boolean,
  },

  data() {
    return {
      amount: 0,
      answersText: [],
      cleanTextarea: false,
      questionSelected: null,
      showTextarea: false,
    };
  },

  computed: {
    answerTextarea() {
      return this.answersOptions.find(item => item.type === ANSWER_TYPES.ANSWER_TYPE_ADDITIONAL_TEXT_FIELD);
    },

    styleObject() {
      return {
        '--mainColor': this.mainColor,
        '--secondaryColor': this.secondaryColor,
        '--color-hover': this.accentColor || this.backgroundColor,
        '--badgeBackgroundColor': this.badgeBackgroundColor,
        '--mainColorWithOpacity': this.opacity(this.mainColor, 0.6),
      };
    },
  },

  mounted() {
    this.answersText = this.answersOptions.map(item => item.value);
    this.loadAnswer();
  },

  methods: {
    // Loads the value of the additional field (if exists)
    loadAdditionalField() {
      const additionalOption = this.findAdditionalAnswer(this.answersOptions);
      if (additionalOption && additionalOption.value !== this.$i18n.t('questionType2.other')) {
        this.restoreAdditionalOption(additionalOption);
        let isSelected = false;
        if (this.questionSelected) {
          isSelected = this.questionSelected.answers.findIndex(answer => answer.id === additionalOption.id) > -1;
        }
        if (additionalOption.value !== '' && isSelected) {
          // Other option is selected (additional field is displayed)
          this.showTextarea = true;
        }
      }
    },

    // Load previous response of the textarea component
    loadAnswer() {
      this.saveQuestionContext();
      this.loadAdditionalField();
    },

    onPillClicked(event, option, question) {
      if (event && event.target.classList.contains('active')) {
        this.amount--;
        this.selectOptionPill(event, option, question);
        if (option.type === ANSWER_TYPES.ANSWER_TYPE_ADDITIONAL_TEXT_FIELD) {
          this.showTextarea = !this.showTextarea;
          this.cleanTextarea = this.showTextarea;
        }
      } else if (this.amount < question.optionsSelected) {
        this.amount++;
        this.selectOptionPill(event, option, question);
        if (option.type === ANSWER_TYPES.ANSWER_TYPE_ADDITIONAL_TEXT_FIELD) {
          this.showTextarea = !this.showTextarea;
          this.cleanTextarea = this.showTextarea;
        }
      }
    },

    opacity(color, alpha) {
      const r = parseInt(color.slice(1, 3), 16),
        g = parseInt(color.slice(3, 5), 16),
        b = parseInt(color.slice(5, 7), 16);
      if (alpha) {
        return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')';
      } else {
        return 'rgb(' + r + ', ' + g + ', ' + b + ')';
      }
    },

    // Extra option is set to its default value
    restoreAdditionalOption() {
      const numberOfOptions = this.answersOptions.length;
      this.answersText[numberOfOptions - 1] = this.$i18n.t('questionType2.other');
    },

    // Save selected question (local & store, if question exist)
    saveQuestionContext() {
      if (this.questionSelected) {
        this.amount = this.questionSelected.answers.length;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.shadow-custom {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.7) !important;
}
.badge {
  color: var(--mainColorWithOpacity);
  background-color: var(--badgeBackgroundColor);
  margin-bottom: 0.25rem;
  margin-right: 0.5rem;
  &:hover {
    cursor: pointer;
  }
}
.active {
  background-color: var(--color-hover);
  border-color: var(--color-hover);
  color: var(--secondaryColor);
  opacity: 1;
}
.poll-question-2__satisfaction {
  .badge {
    border: 2px solid rgba(60, 60, 62, 0.12);
    box-shadow: none !important;
    font-weight: 400;
    @media only screen and (min-width: 600px) {
      font-size: 0.875rem;
    }
  }
}
@media (hover: hover) and (pointer: fine) {
  .badge:hover {
    background-color: var(--color-hover);
    border-color: var(--color-hover);
    color: var(--secondaryColor);
    opacity: 1;
  }
}
</style>
