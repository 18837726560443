<template>
  <embed :src="image" :type="getEmbedType" alt="imagen" class="backImage mx-auto my-3" />
</template>

<script>
export default {
  name: 'backImage',
  props: {
    image: String,
  },
  computed: {
    // this method gets the media type (image/png|gig or video/mp4)
    getEmbedType() {
      const ext = this.image !== undefined ? this.image.split('.').pop() : 'png';
      let embedType;
      switch (ext) {
        case 'png':
        case 'gif':
          embedType = `image/${ext}`;
          break;
        case 'mp4':
          embedType = `video/${ext}`;
          break;
        default:
          break;
      }
      return embedType;
    },
  },
};
</script>
