<template>
  <div>
    <div class="mb-4" v-if="question.type === getQuestionTypes.RANGE">
      <QuestionHeader
        :showSteper="showSteper"
        :questionId="question.id"
        :mainColor="mainColor"
        :secondaryColor="secondaryTextColor"
        :accentColor="accentColor"
        :headTitle="question.name"
        :question="question.value"
        :isSatisfactionSurvey="isSatisfactionSurvey"
        margin="mb-5"
      />
      <QuestionType1
        :question="question"
        :selectOption="selectOption"
        :selectOptionPill="selectOptionPill"
        :mainColor="mainColor"
        :secondaryColor="secondaryTextColor"
        :accentColor="accentColor"
        :answersOptions="question.answers"
        :handleInputs="handleInputs"
        :backgroundColor="backgroundColor"
        :currentView="currentView"
      />
    </div>
    <div class="mb-2" v-if="question.type === getQuestionTypes.MULTIPLE">
      <QuestionHeader
        :showSteper="showSteper"
        :questionId="question.id"
        :mainColor="mainColor"
        :secondaryColor="secondaryTextColor"
        :accentColor="accentColor"
        :headTitle="question.name"
        :question="question.value"
        :isSatisfactionSurvey="isSatisfactionSurvey"
        margin="mb-5"
      />
      <QuestionType2
        :question="question"
        :selectOption="selectOption"
        :selectOptionPill="selectOptionPill"
        :mainColor="mainColor"
        :secondaryColor="secondaryTextColor"
        :accentColor="accentColor"
        :backgroundColor="backgroundColor"
        :badgeBackgroundColor="badgeBackgroundColor"
        :answersOptions="question.answers"
        :handleInputs="handleInputs"
        :handleTextArea="handleTextArea"
        :isSatisfactionSurvey="isSatisfactionSurvey"
        :key="question.id"
      />
    </div>
    <div class="mb-4" v-if="question.type === getQuestionTypes.OPEN">
      <QuestionHeader
        :showSteper="showSteper"
        :questionId="question.id"
        :mainColor="mainColor"
        :secondaryColor="secondaryTextColor"
        :accentColor="accentColor"
        :headTitle="question.name"
        :question="question.value"
        :isSatisfactionSurvey="isSatisfactionSurvey"
        margin="mb-5"
      />
      <QuestionType4
        :question="question"
        :selectOption="selectOption"
        :selectOptionPill="selectOptionPill"
        :mainColor="mainColor"
        :secondaryColor="secondaryTextColor"
        :accentColor="accentColor"
        :answersOptions="question.answers"
        :handleInputs="handleInputs"
        :handleTextArea="handleTextArea"
        :isSatisfactionSurvey="isSatisfactionSurvey"
      />
    </div>
    <div class="mb-4" v-if="question.type === getQuestionTypes.YESNO">
      <QuestionHeader
        :showSteper="showSteper"
        :questionId="question.id"
        :mainColor="mainColor"
        :secondaryColor="secondaryTextColor"
        :accentColor="accentColor"
        :headTitle="question.name"
        :question="question.value"
        :isSatisfactionSurvey="isSatisfactionSurvey"
        margin="mb-5"
      />
      <QuestionType5
        :questionId="question.id"
        :selectOption="selectOption"
        :selectOptionPill="selectOptionPill"
        :mainColor="mainColor"
        :secondaryColor="secondaryTextColor"
        :accentColor="accentColor"
        :answersOptions="question.answers"
        :isSatisfactionSurvey="isSatisfactionSurvey"
        :handleInputs="handleInputs"
      />
    </div>
    <div class="mb-4" v-if="question.type === getQuestionTypes.EXIT_POLL">
      <QuestionHeader
        :showSteper="showSteper"
        :questionId="question.id"
        :mainColor="mainColor"
        :secondaryColor="secondaryTextColor"
        :accentColor="accentColor"
        :headTitle="question.name"
        :question="question.value"
        :isSatisfactionSurvey="isSatisfactionSurvey"
        margin="mb-5"
      />
      <QuestionType3
        :question="question"
        :questionId="question.id"
        :selectOption="selectOption"
        :selectOptionPill="selectOptionPill"
        :mainColor="mainColor"
        :secondaryColor="secondaryTextColor"
        :accentColor="accentColor"
        :answersOptions="question.answers"
        :handleInputs="handleInputs"
        :handleTextArea="handleTextArea"
      />
    </div>
    <div class="mb-4" v-if="question.type === getQuestionTypes.STARS">
      <QuestionHeader
        :showSteper="showSteper"
        :questionId="question.id"
        :mainColor="mainColor"
        :secondaryColor="secondaryTextColor"
        :accentColor="accentColor"
        :headTitle="question.name"
        :question="question.value"
        :isSatisfactionSurvey="isSatisfactionSurvey"
        margin="mb-5"
      />
      <QuestionType6
        :question="question"
        :selectOption="selectOption"
        :selectOptionPill="selectOptionPill"
        :textColor="mainColor"
        :color="getStarsColor"
        :answersOptions="question.answers"
        :handleInputs="handleInputs"
        :currentView="currentView"
      />
    </div>
  </div>
</template>

<script>
import { QUESTION_TYPE } from '../../constants/NpsConstants';
import QuestionHeader from '@/components/QuestionHeader.vue';
import QuestionType1 from './Views/QuestionType1';
import QuestionType2 from './Views/QuestionType2';
import QuestionType3 from './Views/QuestionType3';
import QuestionType4 from './Views/QuestionType4';
import QuestionType5 from './Views/QuestionType5';
import QuestionType6 from './Views/QuestionType6';

// @ is an alias to /src
export default {
  name: 'Survey',
  components: {
    QuestionHeader,
    QuestionType1,
    QuestionType2,
    QuestionType3,
    QuestionType4,
    QuestionType5,
    QuestionType6,
  },
  props: {
    question: Object,
    currentView: Object,
    answersOptions: Array,
    mainColor: String,
    secondaryColor: String,
    secondaryTextColor: String,
    accentColor: String,
    selectOptionPill: Function,
    selectOption: Function,
    handleInputs: Function,
    handleTextArea: Function,
    backgroundColor: String,
    badgeBackgroundColor: String,
    showSteper: Boolean,
    isSatisfactionSurvey: Boolean,
  },
  beforeUpdate() {
    this.currentView && this.$analytics.sendViewOpened(this.currentView.viewName);
  },
  computed: {
    getQuestionTypes() {
      return QUESTION_TYPE;
    },
    getStarsColor() {
      return this.secondaryColor || this.backgroundColor;
    },
  },
};
</script>
