import BaseHttpClient from '@/http/BaseHttpClient';
import { httpResources } from '@/config/constants';

class ShippingsRequest extends BaseHttpClient {
  constructor() {
    super();
  }

  async getShippingByUid(uid) {
    try {
      const resource = httpResources.SHIPPINGS.replace('{uid}', uid);
      const response = await this.httpClient.get(resource);
      this.setResponse(response);
    } catch (error) {
      this.setError(error);
    }
  }
}

export default new ShippingsRequest();
