<template>
  <div class="form-group">
    <input
      v-model="field"
      type="text"
      class="form-control border-0 form-control-lg"
      id="orderNumber"
      :placeholder="$t('viewExampleButtonInput.placeholder')"
      name="order"
    />
    <label for="orderNumber" />
    <button type="button" class="btn font-weight-bold" data-toggle="modal" data-target="#qrModal" :style="styleObject">
      {{ $t('viewExampleButtonInput.buttonText') }}
    </button>
  </div>
</template>

<script>
export default {
  name: 'ViewExampleButtonInput',
  data() {
    return {
      field: '',
    };
  },

  props: {
    mainColor: String,
    backgroundColor: String,
    onFieldChanged: Function,
  },

  computed: {
    styleObject() {
      return {
        '--mainColor': this.mainColor,
        '--secondaryColor': this.secondaryColor,
        '--color-hover': this.accentColor,
        '--background': this.backgroundColor,
      };
    },
  },

  watch: {
    field(newValue) {
      this.onFieldChanged(newValue);
    },
  },
};
</script>

<style lang="scss" scoped>
div {
  position: relative;
  & input {
    width: 100%;
  }
  & button {
    position: absolute;
    top: 20%;
    right: 2%;
    font-size: 0.8rem;
    background: var(--background);
    border: var(--background);
    color: var(--mainColor);
    opacity: 0.54;
  }
}
</style>
