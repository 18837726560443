import BaseHttpClient from '@/http/BaseHttpClient';
import { httpResources } from '@/config/constants';

class PollRequest extends BaseHttpClient {
  constructor() {
    super();
  }

  async getPoll(pollId) {
    try {
      const resource = httpResources.POLLS.replace('{poll_id}', pollId);
      const response = await this.httpClient.get(resource);
      this.setResponse(response);
    } catch (error) {
      this.setError(error);
    }
  }
}

export default new PollRequest();
