export const httpResources = {
  SEND_POLL: '/polls/record',
  POLLS: '/polls/{poll_id}',
  THEME: '/polls/setting?slug={slug_name}',
  SHIPPINGS: '/shippings/{uid}',
};

export const httpStatusString = {
  OK: 'Ok',
  BAD_REQUEST: 'Bad Request',
  FORBIDDEN: 'Forbidden',
  NOT_FOUND: 'Not Found',
  TIMEOUT: 'Timeout',
  CONFLICT: 'Conflict',
  ERROR: 'Server Error',
};

export const httpStatus = {
  OK: 200,
  BAD_REQUEST: 400,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  TIMEOUT: 408,
  CONFLICT: 409,
  EXPECTATION_FAILED: 417,
  UNPROCESSABLE_ENTTITY: 422,
  SERVER_ERROR: 500,
};

export const httpErrorStatus = [
  httpStatus.BAD_REQUEST,
  httpStatus.CONFLICT,
  httpStatus.EXPECTATION_FAILED,
  httpStatus.FORBIDDEN,
  httpStatus.NOT_FOUND,
  httpStatus.SERVER_ERROR,
  httpStatus.TIMEOUT,
  httpStatus.UNPROCESSABLE_ENTTITY,
];

export const SATISFACTION_SURVEY_URL = process.env.VUE_APP_SURVEY_SATISFACTION_URL;
