import { mapGetters } from 'vuex';
import { STORES, ANSWER_TYPES } from '../constants/NpsConstants';
import { MUTATION_TYPES } from '../store/mutation-types';

// Used to find the additional field answer
export const AdditionalFieldMixin = {
  methods: {
    // Find additional text field type answer (3)
    findAdditionalAnswer(answers) {
      return answers.find(answer => answer.type === ANSWER_TYPES.ANSWER_TYPE_ADDITIONAL_TEXT_FIELD);
    },
  },
};

// Manage back button logic
export const GoBackToLastQuestionMixin = {
  computed: {
    ...mapGetters(STORES.POLL_SETTINGS, ['getCurrentView']),
  },
  methods: {
    // switch to the previous view
    goBackToLastQuestion() {
      this.$analytics.sendBackButtonPressed(this.getCurrentView ? this.getCurrentView.viewName : 'User data');
      this.$store.commit(`${STORES.SEND_POLL_INFO_STORE}/${MUTATION_TYPES.SET_POLL_STEP}`, -1);
      this.$store.commit(`${STORES.POLL_SETTINGS}/${MUTATION_TYPES.SET_VIEW_BACK_ANSWER}`);
      this.$store.commit(`${STORES.SEND_POLL_INFO_STORE}/${MUTATION_TYPES.CLEAR_QUESTIONS}`, null, { root: true });
    },
  },
};

// Initialize question status / values
export const InitialValuesMixin = {
  computed: {
    ...mapGetters(STORES.SEND_POLL_INFO_STORE, ['getQuestionAsweredById']),
  },

  mounted() {
    this.questionSelected = this.getQuestionAsweredById(this.question.id);
    if (this.questionSelected) {
      this.$store.commit(`${STORES.SEND_POLL_INFO_STORE}/${MUTATION_TYPES.ADD_QUESTION}`, { ...this.questionSelected });
    }
  },
};

// Determines if an option is active or not
export const OptionMixin = {
  data: () => ({
    questionSelected: null,
  }),

  computed: {
    // indicates whether an option is active or not
    optionIsActive() {
      return option => {
        let isActive = false;
        if (this.questionSelected) {
          const { answers } = this.questionSelected;
          isActive = answers.findIndex(answer => answer.value === option && option !== '') > -1;
        }
        return isActive;
      };
    },
  },
};
