<template>
  <div class="wrapper" v-bind:style="{ background: this.getTheme.backgroundColor }">
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-6 offset-md-3">
          <div
            class="mainWrapper d-flex flex-column justify-content-between"
            v-bind:style="{ fontFamily: [this.getTheme.secondaryFont, this.getTheme.mainFont] }"
          >
            <div class="mb-md-2">
              <div class="row mb-5">
                <div class="col-12 mt-5">
                  <img :src="this.getLogo" alt="logo" class="img-fluid" width="100" height="100" />
                </div>
              </div>
              <!-- Dynamic Question -->
              <div class="row mb-5">
                <div class="col-12 col-lg-10 offset-lg-1 col-xl-8 offset-xl-2 text-left text-md-center">
                  <div class="px-4">
                    <h1 class="mb-3" v-bind:style="{ color: this.getTheme.mainTextColor, fontWeight: '600' }">
                      {{ $t('uniquePollError.title') }}
                    </h1>
                    <span v-bind:style="{ color: this.getTheme.mainTextColor, opacity: '0.57' }">
                      {{ $t('uniquePollError.message') }}</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapGetters } from 'vuex';
import { STORES } from '../constants/NpsConstants';
// @ is an alias to /src

export default {
  name: 'UniquePollErrorView',

  computed: {
    ...mapGetters(STORES.POLL_SETTINGS, ['getLogo', 'getTheme', 'showBottomButton']),
  },
};
</script>
