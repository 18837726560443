//Api constants
export const FIELDS = {
  ANSWER_ID: 'answer_id',
  ANSWER_TYPE_ID: 'answer_type_id',
  ANSWERS: 'answers',
  CHECKPOINT: 'checkpoint',
  COLOR: 'color',
  HELP_TEXT: 'help_text',
  HELP_TEXT_EXTRA_ONE: 'help_text_extra_1',
  HELP_TEXT_EXTRA_TWO: 'help_text_extra_2',
  ID: 'id',
  IMAGE: 'image',
  LOCALE: 'locale',
  LOGO: 'logo',
  MAIN_BASE_COLOR: 'main_base_color',
  MAIN_COLOR: 'main_color',
  MAIN_FONT: 'main_font',
  MAIN_TEXT_COLOR: 'main_text_color',
  NAME: 'name',
  NEXT_VIEW_ID: 'next_view_id',
  NEXT_QUESTION_ID: 'next_question_id',
  OPTIONS_SELECTED: 'options_selected',
  ORDER: 'order',
  ORDER_ID: 'order_id',
  ORIGIN_ID: 'origin_id',
  PHONE_CODE: 'phone_code',
  PHONE_NUMBER: 'phone_number',
  POLL_ID: 'poll_id',
  QUESTION_ID: 'question_id',
  QUESTION_TYPE_ID: 'question_type_id',
  QUESTIONS: 'questions',
  SECONDARY_COLOR: 'secondary_color',
  SECONDARY_FONT: 'secondary_font',
  SECONDARY_TEXT_COLOR: 'secondary_text_color',
  SHIPPINGS_UID: 'shippings_uid',
  SOURCE_ID: 'source_id',
  STORE_ID: 'store_id',
  TEXT_COLOR_OVER_MAIN_COLOR: 'text_color_over_main_color',
  UID: 'uid',
  USER: 'user',
  USER_CODE: 'user_code',
  USER_ID: 'user_id',
  USER_NAME: 'user_name',
  USER_POLL_ID: 'user_poll_id',
  VALUE: 'value',
  VIEW_ID: 'view_id',
  VIEWS: 'views',
  VIEW_NAME: 'name',
  VIEW_QUESTIONS: 'view_questions',
  VIEW_TYPE_ID: 'view_type_id',
};
