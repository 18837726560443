export const MUTATION_TYPES = {
  ADD_ANSWERED_QUESTION: 'addAnsweredQuestion',
  ADD_QUESTION: 'addQuestion',
  CLEAR_QUESTIONS: 'clearQuestions',
  FINISH_POLL: 'finishPoll',
  MOVE_QUESTIONS_TO_ADD: 'moveQuestionsToAdd',
  POLL_INITIALIZED: 'pollInitialized',
  SET_CURRENT_VIEW_ID: 'setCurrentViewId',
  SET_EXIT_POLL: 'setExitPoll',
  SET_ORDER_ID: 'setOrderId',
  SET_POLL_ID: 'setPollId',
  SET_POLL_INFO: 'setPollInfo',
  SET_POLL_STEP: 'setPollStep',
  SET_POLL_THEME: 'setPollTheme',
  SET_POLL_VIEWS: 'setPollViews',
  SET_USER_PHONE: 'setUserPhone',
  SET_USER_POLL_ID: 'setUserPollId',
  SET_VALIDATION_ERROR: 'setValidationError',
  SET_VIEW_AS_ANSWERED: 'setViewAsAnswered',
  SET_VIEW_BACK_ANSWER: 'setViewAsBackAnswer',
  SET_POLL_DATA: 'setPollData',
  SET_IS_LOAD_DATA: 'setIsLoadData',
  SET_SEND_INFO_MESSAGE_FINISHED: 'setSendInfoMessageFinished',
  SET_SHIPPING_AS_ANSWERED: 'setShippingAsAnswered',
  SET_SATISFACTION_SURVEY_URL: 'setSatisfactionSurveyUrl',
};
