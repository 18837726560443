export default class Answer {
  constructor(id, nextViewId, order, type, value, nextQuestionId) {
    this.id = id;
    this.nextViewId = nextViewId;
    this.order = order;
    this.type = type;
    this.value = value;
    this.nextQuestionId = nextQuestionId;
  }
}
