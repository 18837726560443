export default class View {
  constructor(id, isCheckpoint, nextViewId, order, questions, typeId, viewName) {
    this.answered = false;
    this.id = id;
    this.isCheckpoint = isCheckpoint === 1;
    this.nextViewId = nextViewId;
    this.order = order;
    this.questions = questions;
    this.typeId = typeId;
    this.viewName = viewName;
  }

  ///Method that returns true if at the end of the view, the answers should be
  ///sent to the service
  isViewCheckpoint() {
    return this.isCheckpoint;
  }

  ///Method that returns true if the view has already been seen and answered
  ///by the user
  isViewAnswered() {
    return this.answered;
  }

  ///Method called when the user has completed the view
  setViewAsAnswered() {
    this.answered = true;
  }

  ///Method called when the user has completed the view
  setViewAsNotAnswered() {
    this.answered = false;
  }
}
